const styles = () => ({
  customColorPickerContainer: {
    background: "white",
    width: "300px",

    "& .popup_tabs": {
      boxShadow: "none",
    },

    ".customColorPickerWrapper": {
      padding: "10px 0px",
      margin: "0px 16px",
      borderTop: "1px solid #DCE4EC"
    },

    ".colorTitleWrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "10px",

      ".MuiTypography-root": {
        fontWeight: "600",
      },

      ".editBtn": {
        textTransform: "none",
        color: "#2563EB",
        textDecoration: "underline",
        padding: "0px",
        minWidth: "unset"
      },
    },

    ".colorPickerBtnContainer": {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
    },

    ".colorPicketBtn": {
      width: "26px",
      height: "26px",
      outline: "none",
      borderRadius: "50%",
      cursor: "pointer",
      border: "1.5px solid #DCE4EC",
    },

    ".colorPicketBtnActive": {
      borderRadius: "50%",
      padding: "1px",
      border: "1.5px solid rgb(83, 83, 249)",
      width: "fit-content",
      height: "fit-content",
      display: "flex",
      justifyContent: "center",
    },
  },
});

export default styles;
