import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";

export const insertButton = (editor) => {
  const button = {
    type: "button",
    children: [{ text: "" }],
    buttonLink: {
      linkType: "webAddress",
    },
    iconPosition: "start"
  };
  Transforms.insertNodes(editor, button);
  Transforms.move(editor);
  insertNewLine(editor);
};
