const style = () => ({
  ".fs-12": { fontSize: "12px" },
  ".fs-14": { fontSize: "14px" },
  ".fw-500": { fontWeight: "500" },
  ".fw-600": { fontWeight: "600" },
  ".pointer": { cursor: "pointer" },
  ".transformNone": { textTransform: "none" },
  ".settingsContainer": {
    width: "340px",
    padding: "16px 16px",
    background: "#fff",
    ".header": {
      borderBottom: `1px solid #DCE4EC`,
      paddingBottom: "8px",
      //   marginBottom: "16px",
    },
  },
  ".title": {
    fontWeight: "700",
    fontSize: "14px",
  },
  ".closeBtn": {
    width: "26px",
    height: "26px",
    BorderRadius: "4px",
    flexShrik: "0",
    padding: "4px",
    background: " #F8FAFC",
    borderRadius: "4px",
  },
  ".MuiTabs-scroller": {
    borderBottom: `1px solid #DCE4EC`,
  },
  ".themeCardWrapper": {
    borderRadius: "4px",
    ".active": {
      background: "#ECF2FF",
    },
  },
  ".primaryCard, .secondaryCard": {
    width: "44px",
    height: "44px",
    borderRadius: "5px",
    border: `1px solid #929292`,
  },

  ".flexAlign": {
    display: "flex",
    alignItems: "center",
  },
  ".activeColorBox, .xsColorBox": {
    width: "24px",
    height: "24px",
    borderRadius: "5px",
    border: `1px solid #929292`,
  },
  ".themeCard": {
    borderRadius: "7px",
    padding: "16px",
    border: "1px solid #F3F3F3",
    boxShadow: "1px 2px 5px 0px #00000014",
    position: "relative",
    flexWrap: "nowrap",
    ".tickIcon": {
      visibility: "hidden",
      opacity: "0",
      position: "absolute",
      right: "-8px",
      top: "-8px",
    },
    "&.selected": {
      ".tickIcon": {
        visibility: "visible",
        opacity: "1",
      },
    },
  },
  ".otherColors": {
    display: "flex",
    flexWrap: "wrap",
    width: "48px",
    ".smallCard": {
      width: "20px",
      height: "20px",
      borderRadius: "5px",
      border: `1px solid #929292`,
    },
  },
  ".imageUpload": {
    background: "#F3F3F3",
    borderRadius: "12px",
    padding: "10px",
    height: "208px",
    position: "relative",
    "& .resetIcon": {
      position: "absolute",
      right: "10px",
      top: "10px",
    },
    ".changeImgText": {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1,
      background: "#0000004D",
      color: "#fff",
      padding: "6px",
      borderRadius: "6px",
      fontSize: "12px",
      fontWeight: 600,
      opacity: "0",
      visibility: "hidden",
    },
    "&:hover": {
      ".changeImgText": {
        opacity: "1",
        visibility: "visible",
      },
    },
  },
  ".imgUploadInner": {
    background: "#fff",
    borderRadius: "10px",
    border: "0.79px dashed #8360FD",
    height: "100%",
  },
  ".outlineBtn": {
    color: "#2563EB",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px 0px #00000026",
    border: "1px solid #2563EB",
    background: "#EBF1F9",
    height: "34px",
    textTransform: "unset",
  },
  ".seondaryOutlineBtn": {
    border: "1px solid #D8DDE1",
    borderRadius: "8px",
    // boxShadow: "0px 4px 10px 0px #00000026",
    height: "34px",
    textTransform: "unset",
    background: "#eceef3",
    color: "#64748B",
  },
  ".blueBtn": {
    boxShadow: "0px 4px 10px 0px #00000026",
    background: "#2563EB",
    borderRadius: "8px",
    textTransform: "unset",
    height: "34px",
    color: "#fff",
    "&.disabled": {
      background: "#D9D9DD !important",
      color: "#fff !important",
    },
  },
  ".generatedItem": {
    border: "1px solid #E4E6E9",
    borderRadius: "7px",
    padding: "14px",
    display: "flex",
    gap: "16px",
    position: "relative",
    justifyContent: "space-between",
    marginBottom: "12px",
    ".tickIcon": {
      visibility: "hidden",
      opacity: "0",
      position: "absolute",
      right: "-8px",
      top: "-8px",
    },
    "&.selected": {
      border: "1px solid #2563EB",
      boxShadow: " 3px 3px 8px 0px #2563EB40",
      ".tickIcon": {
        visibility: "visible",
        opacity: "1",
      },
    },
    ".colorBox": {
      border: "1px solid #929292",
      height: "28px",
      width: "28px",
      borderRadius: "5px",
    },
  },
  ".textArea": {
    background: "#F3F3F3",
    borderRadius: "12px",
    padding: "10px",
    height: "208px",
    "& textarea": {
      width: "100%",
      height: "100% !important",
      border: "0.79px solid #8360FD",
      background: "#FFFFFF",
      borderRadius: "8px",
      padding: "10px",
    },
  },
  ".imageUrl": {
    height: "auto !important",
    ".MuiOutlinedInput-root": {
      border: "0.79px solid #8360FD",
      background: "#FFFFFF",
      borderRadius: "8px",
      ".tickIcon": {
        width: "18px",
      },
    },
    "&.invalidUrl": {
      ".MuiInputAdornment-root": {
        ".tickIcon": {
          ".fillPath": {
            fill: "#D9D9DD!important",
          },
        },
      },
    },
  },
  ".saveThemeContainer": {
    ".MuiOutlinedInput-root": {
      borderRadius: "8px",
      boxShadow: "0px 4px 16px 0px #0000000D",
    },
  },
  ".uploadedImage": {
    width: "126px",
    height: "126px",
    borderRadius: "126px",
  },
});

export default style;
