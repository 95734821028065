import { fontOptions } from "../../utils/font";

const buttonStyle = [
  {
    tab: "General",
    value: "size",
    fields: [
      {
        label: "Button Text",
        key: "label",
        type: "text",
      },
      {
        label: "Font Family",
        key: "fontFamily",
        type: "textOptions",
        options: fontOptions,
        width: 7,
        renderOption: (option) => {
          return (
            <span style={{ fontFamily: option.value }}>{option.text}</span>
          );
        },
        themeEnabled: true
      },
      {
        label: "Font Size",
        key: "textSize",
        type: "fontSize",
        width: 5,
        placeholder: "16px or 1em",
      },
      {
        label: "Alignment",
        key: "textAlign",
        type: "textAlign",
        placeholder: "16px or 1em",
      },
    ],
  },
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Text Color",
        key: "textColor",
        type: "color",
        needPreview: true
      },
      {
        label: "Button Color",
        key: "bgColor",
        type: "color",
        themeEnabled: true
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
    ],
  },
  // {
  //   tab: "Link",
  //   value: "link",
  //   fields: [
  //     {
  //       label: "Button Link",
  //       key: "buttonLink",
  //       type: "buttonLink",
  //     },
  //   ],
  // },
  {
    tab: "Banner Spacing",
    value: "bannerSpacing",
    fields: [
      {
        label: "Hide on Mobile",
        key: "xsHidden",
        type: "selectBox",
        placeholder: "Hide on Mobile",
      },
      {
        label: "Banner Spacing",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border",
    value: "border",
    fields: [
      {
        label: "Border Radius",
        key: "borderRadius",
        type: "borderRadius",
      },
      {
        label: "Border Width",
        key: "borderWidth",
        type: "text",
        placeholder: "1px",
        width: 6,
      },
      {
        label: "Border Style",
        key: "borderStyle",
        type: "textOptions",
        width: 6,
        options: [
          {
            text: "Solid",
            value: "solid",
          },
          {
            text: "Dotted",
            value: "dotted",
          },
          {
            text: "Dashed",
            value: "dashed",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
  {
    tab: "Icon",
    value: "icon",
    fields: [
      {
        label: "Icon Position",
        key: "iconPosition",
        type: "textOptions",
        options: [
          {
            value: "start",
            label: "Start",
          },
          {
            value: "end",
            label: "End",
          },
        ],
      },
      {
        label: "Button Icons",
        key: "buttonIcon",
        type: "icons",
      },
    ],
  },
  {
    tab: "Hover Colors",
    value: "hoverColors",
    fields: [
      {
        label: "Hover Text Color",
        key: "textColorHover",
        type: "color",
        needPreview: true,
      },
      {
        label: "Hover Button Color",
        key: "bgColorHover",
        type: "color",
      },
      {
        label: "Hover Border Color",
        key: "borderColorHover",
        type: "color",
      },
    ],
  },
  {
    tab: "Save As Template",
    value: "saveAsTemplate",
    needActions: false,
    fields: [
      {
        label: "Template Image",
        key: "saveAsTemplate",
        type: "saveAsTemplate",
      },
    ],
  },
];

export default buttonStyle;
