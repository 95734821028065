import React from "react";
import { Select, MenuItem } from "@mui/material";
import {
  addMarkData,
  activeMark,
  getSelectedElementStyle,
} from "../../utils/SlateUtilityFunctions.js";
import { fontFamilyMap } from "../../utils/font";
import { toolbarGroups } from "../toolbarGroups.js";

const allTools = toolbarGroups.flat();
const fontWeight = allTools.find((f) => f.format === "fontWeight");

const getValue = (editor, format) => {
  switch (format) {
    case "fontFamily": {
      const style = getSelectedElementStyle("font-family", editor);
      const val = Object.entries(fontFamilyMap).find(
        ([key, value]) => value === style
      )?.[0];

      return val || fontFamilyMap.PoppinsRegular;
    }
    case "fontWeight": {
      const { options } = fontWeight || {};

      const fontWeightStyle = getSelectedElementStyle("font-weight", editor);

      const selected = options?.find(
        (o) => o.value === fontWeightStyle || o.numVal === fontWeightStyle
      );

      return selected?.value;
    }
    default:
      return activeMark(editor, format);
  }
};

const Dropdown = ({ classes, editor, format, options, width }) => {
  const value = getValue(editor, format);

  const changeMarkData = (event, format) => {
    event.preventDefault();
    const value = event.target.value;
    addMarkData(editor, { format, value });
  };

  return (
    <Select
      value={value}
      className="editor-dd"
      onChange={(e) => changeMarkData(e, format)}
      style={{
        fontFamily: fontFamilyMap[value],
        width: width || "100%",
        height: "36px",
        borderRadius: "10px",
        fontSize: "14px",
      }}
      MenuProps={{
        PaperProps: {
          sx: classes?.textOptions,
        },
      }}
    >
      {options.map((item, index) => (
        <MenuItem
          style={{ fontFamily: item.text }}
          key={index}
          value={item.value}
        >
          {item.text}
        </MenuItem>
      ))}
    </Select>
  );
};

Dropdown.defaultProps = {
  icon: null,
};

export default Dropdown;
