import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";

// Style
import Style from "./style";

// icons
import { CloseIcon } from "../common/iconslist";

const SaveTheme = (props) => {
  const {
    className,
    enableGenerateTheme,
    saveBtnLabel,
    loading,
    onSave,
    defaultFormData = {},
  } = props;

  // State
  const [saveTheme, setSaveTheme] = useState(false);
  const [error, setError] = useState({});
  const [form, setForm] = useState(defaultFormData);
  const { name, mood_name } = form;
  const updateForm = (update) => {
    setForm((prev) => ({ ...prev, ...update }));
  };

  useEffect(() => {
    setForm(defaultFormData);
  }, [defaultFormData]);

  const onClose = () => {
    setSaveTheme(false);
    setError({});
    setForm({});
  };

  const validate = () => {
    let err = {};

    if (!name) {
      err.name = "This field is required!";
    }

    if (!mood_name) {
      err.mood_name = "This field is required!";
    }

    return err;
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        className={`blueBtn ${enableGenerateTheme ? "eanbled" : "disabled"}`}
        // disabled={!enableGenerateTheme}
        onClick={() => setSaveTheme(true)}
      >
        {saveBtnLabel}
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={saveTheme}
        onClose={onClose}
        className={className}
      >
        <Grid className="saveThemeContainer" sx={{ p: 2 }}>
          <Grid container justifyContent={"space-between"}>
            <Typography
              variant="h4"
              sx={{ fontSize: "20px" }}
              className="fw-600"
            >
              Save Your theme
            </Typography>
            <IconButton onClick={onClose} className="closeBtn">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Divider sx={{ mt: 1, mb: 1 }} />

          <Typography variant="body1" className="fw-500" sx={{ pb: 0.5 }}>
            Theme name
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="Ex : Forest green"
            onChange={(e) => updateForm({ name: e.target.value })}
            value={name}
          />
          <Error error={error?.name} />
          <Typography
            variant="body1"
            className="fw-500"
            sx={{ pt: 1, pb: 0.5 }}
          >
            Category
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Ex : Nature and Calm"
            fullWidth
            onChange={(e) => updateForm({ mood_name: e.target.value })}
            value={mood_name}
          />
          <Error error={error?.mood_name} />
          <Grid container justifyContent={"flex-end"} sx={{ mt: 3 }}>
            <Button
              variant="outlined"
              className="seondaryOutlineBtn"
              color="secondary"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="blueBtn"
              sx={{ ml: 1 }}
              disabled={loading}
              onClick={async () => {
                const err = validate();
                setError(err);

                if (Object.keys(err)?.length) {
                  return;
                }

                await onSave({ ...form });
                onClose();
              }}
            >
              {loading ? (
                <CircularProgress size={14} style={{ marginRight: "10px" }} />
              ) : null}

              {saveBtnLabel}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default styled(SaveTheme)(Style);

function Error({ error = "" }) {
  if (error) {
    return (
      <Typography variant="body1" sx={{ color: "red", mt: 1 }}>
        {error}
      </Typography>
    );
  } else {
    return <></>;
  }
}
