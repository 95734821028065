import React, { useState, useEffect } from "react";
import { useSlateStatic, ReactEditor } from "slate-react";
import { Node, Transforms } from "slate";
import { IconButton, Tooltip, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import Icon from "../../common/Icon";
import useResize from "../../utils/customHooks/useResize";
import EmbedPopup from "./EmbedPopup";
import { GridSettingsIcon } from "../../common/iconslist";
import { gradientBorder } from "../../utils/helper";
import { getEmbedURL } from "../../helper";
import { getBreakPointsValue } from "../../helper/theme";

const Video = ({ attributes, element, children, customProps }) => {
  const {
    alt,
    alignment,
    borderRadius,
    borderWidth,
    borderColor,
    borderStyle,
    url,
    xsHidden,
    width: oldWidth,
  } = element;
  const editor = useSlateStatic();
  const [openSetttings, setOpenSettings] = useState(false);
  const [parentDOM, setParentDOM] = useState(null);
  const { readOnly } = customProps;
  const { vertical, horizantal } = alignment || {};
  const path = ReactEditor.findPath(editor, element);

  const getSize = () => {
    if (element?.size === undefined) {
      return { widthInPercent: parseInt(oldWidth) };
    } else {
      return element?.size || {};
    }
  };

  const [size, onMouseDown, resizing, onLoad] = useResize({
    parentDOM,
    size: getSize(),
    onChange: (uSize) => {
      Transforms.setNodes(
        editor,
        {
          size: uSize,
        },
        { at: path }
      );
    },
  });
  const arr = Array.from(Node.elements(editor));
  const ele = arr.find(([elem]) => element === elem);

  useEffect(() => {
    if (editor && ele && ele[1] !== undefined) {
      const dom = ReactEditor.toDOMNode(editor, Node.get(editor, ele[1]));
      setParentDOM(dom);
      onLoad(element?.size || {});
    }
  }, []);

  const ToolBar = () => {
    return (
      <div className="element-toolbar visible-on-hover" contentEditable={false}>
        <Tooltip title="Video Settings" arrow>
          <IconButton onClick={onSettings}>
            <GridSettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Video" arrow>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const onSettings = () => {
    setOpenSettings(true);
  };

  const onSave = (data) => {
    try {
      const updateData = { ...data };
      delete updateData.children;
      Transforms.setNodes(
        editor,
        {
          ...updateData,
        },
        {
          at: path,
        }
      );
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onDelete = () => {
    Transforms.removeNodes(editor, { at: path });
  };

  const getWidth = () => {
    if (resizing) {
      return {
        width: `${size.width}px`,
        height: url ? `${size.height}px` : "auto",
      };
    } else {
      return {
        width: {
          ...getBreakPointsValue(getSize(), null, "overrideReSize", true),
        },
        height: url
          ? {
              ...getBreakPointsValue(getSize(), null, "overrideReSizeH", true),
            }
          : "auto",
      };
    }
  };
  const embedURL = getEmbedURL(element);

  const VideoContent = () => {
    return resizing ? (
      <div
        style={{
          width: "100%",
          height: "100%",
          border: "2px dashed black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon icon="videoPlayer" />
      </div>
    ) : (
      <Box
        component={"iframe"}
        className="embedd-iframe"
        sx={{
          border: "none",
          position: "absolute",
          width: "100%",
          height: "100%",
          maxWidth: "100%",
          left: "0px",
          ...(gradientBorder(borderColor) || {}),
          borderWidth: borderWidth || "1px",
          borderRadius: {
            ...getBreakPointsValue(
              borderRadius || {},
              null,
              "overrideBorderRadius",
              true
            ),
          },
          borderStyle: borderStyle || "solid",
        }}
        src={embedURL}
        title={alt}
      />
    );
  };

  const VideoPlaceholder = () => {
    return !url && !readOnly ? (
      <Box
        component="button"
        className="element-empty-btn"
        onClick={onSettings}
      >
        <Icon icon="video" />
        Embed Video or Other
      </Box>
    ) : (
      <VideoContent />
    );
  };

  return (
    <Box
      {...attributes}
      className="embed has-hover video dpath"
      sx={{
        display: {
          lg: "flex",
          xs: xsHidden ? "none" : "flex",
        },
        flexDirection: "row",
        flexWrap: "wrap", // to support oldWidth with link
        width: `100%`,
        justifyContent: horizantal,
        alignContent: vertical,
      }}
      {...element.attr}
    >
      {openSetttings ? (
        <EmbedPopup
          element={element}
          onSave={onSave}
          onClose={onClose}
          customProps={customProps}
          format={element?.type ? element?.type : "video"}
          onDelete={onDelete}
        />
      ) : null}
      <Box
        component={"div"}
        contentEditable={false}
        sx={{
          position: "relative",
          ...getWidth(),
          maxWidth: "100%",
        }}
      >
        {!readOnly && url && <ToolBar />}
        <VideoPlaceholder />
        {!readOnly && url ? (
          <IconButton
            onPointerDown={onMouseDown}
            style={{
              background: "#FFF",
            }}
            className={"resize-br visible-on-hover"}
          >
            <AspectRatioIcon />
          </IconButton>
        ) : null}
      </Box>
      {children}
    </Box>
  );
};
export default Video;
