import React, { useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  TextareaAutosize,
  Typography,
  styled,
} from "@mui/material";

// Style
import Style from "./style";

// icons
import { CloseIcon } from "../common/iconslist";
import { CloudIcon } from "./icons";
import { TickIcon } from "./icons";
import { ResetIcon } from "./icons";
import { useEditorTheme } from "../hooks/useEditorTheme";
import ActiveTheme from "../themeSettings/ActiveTheme";

function generateUniqueId() {
  const timestamp = Date.now(); // current timestamp in milliseconds
  const randomNum = Math.floor(Math.random() * 1000000); // random number for uniqueness
  return `id_${timestamp}_${randomNum}`;
}

async function getTheme(fileInputRef, onThemeChange, setLoading) {
  const file = fileInputRef.current?.files[0];

  if (file) {
    setLoading(true);
    // Create a FormData object to hold the file
    const formData = new FormData();
    formData.append("file", file); // 'image' is the key that your backend will use to access the file

    try {
      // Send the file using the fetch API
      const response = await fetch("https://aicheck.agenciflow.com/create", {
        method: "POST",
        body: formData,
      });

      // Handle the response
      if (response.ok) {
        const data = await response.json(); // Assuming the response is JSON
        const theme = JSON.parse(data?.response || "{}");

        onThemeChange({
          id: generateUniqueId(),
          name: "AI Generated",
          theme: theme,
        });
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }

    setLoading(false);
  } else {
    console.log("No file selected");
  }
}

const ThemeSettingsAI = (props) => {
  const { className, openAITheme, setOpenAITheme } = props;

  // State
  // const [themeDrawer, setThemeDrawer] = useState(true);
  const [themeTabType, setThemeTabType] = React.useState(0);
  const [enableGenerateTheme, setEnableGenerateTheme] = React.useState(false);
  const [generateTheme] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const { updateTheme } = useEditorTheme();

  const onThemeChange = (theme) => {
    updateTheme(theme, { action: "THEME_CHANGE" });
  };

  //   image upload
  const fileInputRef = React.useRef(null);

  const handleDivClick = () => {
    // Trigger the click event on the hidden file input element
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
      setEnableGenerateTheme(true);
    }
  };

  const handleTabChange = (event, newValue) => {
    setThemeTabType(newValue);
  };

  const handleClose = () => {
    setOpenAITheme(false);
  };

  // themeCard
  // const ThemeCard = () => {
  //   return (
  //     <Grid className="themeCardWrapper">
  //       {/* remove selected based on condition */}
  //       <Grid
  //         container
  //         justifyContent={"space-between"}
  //         className="themeCard selected"
  //       >
  //         <TickIcon />
  //         <Grid>
  //           <Typography variant="body1" className="fs-14 fw-500">
  //             Slate Gray
  //           </Typography>
  //           <Typography
  //             variant="body2"
  //             color={"textSecondary"}
  //             className="fs-12"
  //           >
  //             Muted Elegance
  //           </Typography>
  //         </Grid>
  //         <Grid className="flexAlign" sx={{ gap: "5px" }}>
  //           <Grid className="primaryCard"></Grid>
  //           <Grid className="secondaryCard"></Grid>
  //           <Grid className="otherColors" sx={{ gap: "5px" }}>
  //             <Grid className="smallCard"></Grid>
  //             <Grid className="smallCard"></Grid>
  //             <Grid className="smallCard"></Grid>
  //             <Grid className="smallCard"></Grid>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  return (
    <Grid className={className}>
      <Drawer
        className={className}
        open={openAITheme}
        onClose={handleClose}
        anchor="right"
      >
        <Grid className="settingsContainer">
          {/* Header */}
          <Grid
            container
            justifyContent={"space-between"}
            wrap="nowrap"
            className="header"
          >
            <Grid>
              <Typography variant="body1" className="title">
                Create theme with AI
              </Typography>
            </Grid>
            <IconButton className="closeBtn" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          {/* // Header */}

          <Tabs
            value={themeTabType}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
            sx={{ mb: 2 }}
          >
            <Tab
              label={
                <Typography
                  variant="body1"
                  className="fs-14 fw-500 transformNone"
                >
                  Image
                </Typography>
              }
            />
            <Tab
              label={
                <Typography
                  variant="body1"
                  className="fs-14 fw-500 transformNone"
                >
                  Text
                </Typography>
              }
            />
            <Tab
              label={
                <Typography
                  variant="body1"
                  className="fs-14 fw-500 transformNone"
                >
                  Image link
                </Typography>
              }
            />
          </Tabs>

          {themeTabType === 0 && (
            <Grid className="imageUpload">
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                className="imgUploadInner"
                onClick={handleDivClick}
              >
                {/* image */}
                <input
                  type="file"
                  accept=".png"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                />

                {imageSrc ? (
                  <>
                    <IconButton
                      className="resetIcon"
                      onClick={() => setImageSrc(null)}
                    >
                      <ResetIcon />
                    </IconButton>
                    <Grid className="changeImgText">Change Image</Grid>
                    <img
                      src={imageSrc}
                      alt="Uploaded Preview"
                      className="uploadedImage"
                    />
                  </>
                ) : (
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    direction={"column"}
                  >
                    <CloudIcon />
                    <Typography
                      variant="body1"
                      className="fw-600"
                      sx={{ pb: 0.5 }}
                    >
                      Upload a logo or image
                    </Typography>
                    <Typography
                      variant="body2"
                      className="fs-12"
                      color={"textSecondary"}
                    >
                      Click here to upload PDF, PPT, and image files.
                    </Typography>
                  </Grid>
                )}
                {/* image */}
              </Grid>
            </Grid>
          )}
          {themeTabType === 1 && (
            <Grid className="textArea">
              <TextareaAutosize placeholder="Describe the theme you are interested in..."></TextareaAutosize>
            </Grid>
          )}
          {themeTabType === 2 && (
            <>
              {/* Add class name based condition "validUrl && invalidUrl" */}
              <Grid className="textArea imageUrl invalidUrl">
                <TextField
                  fullWidth
                  placeholder="Add Image URL"
                  size="small"
                  //   value={imageUrl}
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TickIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid className="imageUpload">
                <Grid
                  container
                  justifyContent={"center"}
                  justifyItems={"center"}
                  className="imgUploadInner"
                >
                  {/* image */}
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    direction={"column"}
                  >
                    <img src="" alt="" />
                  </Grid>
                  {/* image */}
                </Grid>
              </Grid>
            </>
          )}

          {/* General action */}
          <Grid>
            <FormControlLabel
              sx={{ mb: 1, mt: 1 }}
              control={
                <Checkbox
                  checked={enableGenerateTheme}
                  onChange={(event) =>
                    setEnableGenerateTheme(event.target.checked)
                  }
                  size="small"
                />
              }
              label={
                <Typography variant="body2" className="fs-12">
                  a generated theme is used, the site text style will also
                  change.
                </Typography>
              }
            />

            <Grid
              container
              justifyContent={generateTheme ? "space-between" : "flex-end"}
            >
              {generateTheme && (
                <Button
                  className="outlineBtn"
                  variant="outlined"
                  size="small"
                  color="primary"
                >
                  Regenerate theme
                </Button>
              )}

              {/* {generateTheme ? (
                <SaveTheme enableGenerateTheme={enableGenerateTheme} />
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  className={`blueBtn ${
                    enableGenerateTheme ? "eanbled" : "disabled"
                  }`}
                  disabled={!enableGenerateTheme}
                  onClick={() => {
                    setGenerateTheme(true);
                    getTheme(fileInputRef, onThemeChange);
                  }}
                >
                  Generate Theme
                </Button>
              )} */}
              <Button
                variant="contained"
                size="small"
                className={`blueBtn ${
                  enableGenerateTheme ? "eanbled" : "disabled"
                }`}
                disabled={loading}
                onClick={() => {
                  // setGenerateTheme(true);
                  getTheme(fileInputRef, onThemeChange, setLoading);
                }}
              >
                {loading ? (
                  <CircularProgress size={18} style={{ marginRight: "10px" }} />
                ) : null}

                {loading ? "Generating..." : "Generate Theme"}
              </Button>
            </Grid>
          </Grid>
          {/* General action */}

          {/* <Divider sx={{ mt: 2, mb: 2 }} /> */}

          {/* Generate Theme list */}
          {/* add "selected" class */}
          {/* <Grid className="generatedItem">
            <TickIcon />
            <Grid className="colorBox" />
            <Grid className="colorBox" />
            <Grid className="colorBox" />
            <Grid className="colorBox" />
            <Grid className="colorBox" />
            <Grid className="colorBox" />
          </Grid> */}
          {/* Generate Theme list */}

          <Divider sx={{ mt: 2, mb: 2 }} />

          {/* Active Theme */}
          <Typography
            variant="body1"
            className="fs-14 fw-600"
            sx={{ mt: 1, mb: 1 }}
          >
            Active Theme
          </Typography>

          <Grid className="themeCardWrapper">
            <Grid
              container
              justifyContent={"space-between"}
              className="themeCard selected"
            >
              <TickIcon />
              <ActiveTheme explore={false} />
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default styled(ThemeSettingsAI)(Style);
