const styles = () => ({
  SttContainer: {
    display: "flex",
    width: "100%",
    padding: "12px",
    zIndex: 1,
  },
  STTInput: {
    width: "100%",
    borderRadius: "8px",

    "&& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2563EB",
    },

    "&& .MuiOutlinedInput-root": {
      background: "rgba(252, 250, 255, 1)",
    },
  },
  AudioVizualizerContainer: {
    width: "100%",
    display: "flex",
    background: "rgba(252, 250, 255, 1)",
    borderRadius: "8px",
    border: "1px solid #2563EB",
    alignItems: "center",
    flexWrap: "wrap",
  },
  AudioVizualizerContent: {
    display: "flex",
    height: "50px",
    width: "100%",
  },
  AudioVisualiser: {
    flexGrow: "1",
    height: "100%",
  },
  TranscriptionContainer: {
    padding: "12px",
  },
});

export default styles;
