import { Grid, Link, Typography } from "@mui/material";
import { useEditorTheme } from "../hooks/useEditorTheme";

function ActiveTheme(props) {
  const { onExplore } = props;

  const { theme } = useEditorTheme();
  const { colors = {} } = theme?.theme?.cssVars || {};
  const { id, name, mood_name = "" } = theme || {};

  const themeColors = Object.values(colors || {});

  return (
    <Grid className="activeThemeInnner">
      <Typography variant="body1" className="title">
        {id ? "Active Theme" : "No theme activated"}
      </Typography>
      <Grid className="flexAlign">
        <Typography variant="body1" className="fs-14">
          {name}
        </Typography>
        <Typography
          variant="body2"
          color={"textSecondary"}
          className="fs-12"
          style={{ marginLeft: "4px" }}
        >
          {id ? ` - ` : null}
          {id
            ? mood_name
            : "Select 'Explore More' to activate a theme and customize your design."}
        </Typography>
      </Grid>
      <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
        <Grid className="flexAlign" sx={{ gap: "10px" }}>
          {id ? (
            <>
              {themeColors?.map((color, i) => {
                return (
                  <Grid
                    className="activeColorBox"
                    key={i}
                    style={{ background: color }}
                  ></Grid>
                );
              })}
            </>
          ) : null}
        </Grid>

        {onExplore && (
          <Link className="pointer fs-12 fw-500" onClick={onExplore}>
            Explore More
          </Link>
        )}
      </Grid>
    </Grid>
  );
}

export default ActiveTheme;
