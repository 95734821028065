import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Button,
  IconButton,
  Typography,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LinkPopupStyles from "./LinkPopupStyles";

const LinkPopup = (props) => {
  const {
    open,
    handleClose,
    linkData,
    handleInputChange,
    handleInsertLink,
    theme,
  } = props;

  const themeType = localStorage.getItem("themeType");
  const classes = LinkPopupStyles(themeType);

  return (
    <Dialog fullWidth open={open} className={`dialogComp`}>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" className="popupTitle" color={"primary"}>
            LINK
          </Typography>
          <div style={{ display: "flex" }}>
            <IconButton onClick={handleClose} className="close-popupbtn">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid
          item
          xs={12}
          style={{ paddingTop: "12px" }}
          className={classes.titleTypo}
        >
          <TextField
            size="small"
            fullWidth
            value={linkData?.name}
            name={"name"}
            placeholder="Link Title"
            onChange={handleInputChange}
            sx={classes.addLinkField}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "12px" }}>
          <TextField
            size="small"
            fullWidth
            name={"url"}
            value={linkData?.url}
            placeholder="https://google.com"
            onChange={handleInputChange}
            sx={classes.addLinkField}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 1 }}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name={"showInNewTab"}
                  checked={linkData?.showInNewTab}
                  onChange={handleInputChange}
                />
              }
              label={
                <Typography variant="body1" color={"primary"} sx={{ pl: 1 }}>
                  Open in new tab
                </Typography>
              }
            />
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="secondaryBtn">
          Cancel
        </Button>
        <Button onClick={handleInsertLink} className="primaryBtn">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkPopup;
