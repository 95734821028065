import { useState, useEffect, useRef, cloneElement } from "react";
import PropTypes from "prop-types";
import STTStyles from "./style";
import { Grid, IconButton, Box } from "@mui/material";
import { AudioWave } from "./AudioWave";
import {
  CloseGreyCircle,
  PauseRecordingIcon,
  TickBlueCircle,
} from "../../../assets/svg/AIIcons";

function STT(props) {
  const { otherProps, onSend, setIsRecording } = props;
  const { services } = otherProps;
  const classes = STTStyles();
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [chunkIndex, setChunkIndex] = useState(0);
  const audioChunksRef = useRef([]);

  const [transcription, setTranscription] = useState("");
  const [showPause, setShowPause] = useState(true);

  const setChunk = (event) => {
    if (event.data.size > 0) {
      setAudioChunks((prev) => {
        const updatedChunks = [...prev, event.data];
        audioChunksRef.current = updatedChunks; // Update ref manually
        return updatedChunks;
      });
    }
  };

  const sendChunck = async (isLast = false) => {
    if (audioChunksRef.current.length > 0) {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: "audio/webm",
      });
      const formData = new FormData();
      formData.append("audio", audioBlob, `audio_chunk_${chunkIndex}.wav`);
      formData.append("chunkIndex", chunkIndex); // Send chunk index to the backend
      if (isLast) {
        formData.append("isLastChunk", true); // Send chunk index to the backend
      } else {
        formData.append("isLastChunk", false);
        setChunkIndex((prevIndex) => prevIndex + 1);
      }

      const result = await services("speechToText", { formData });
      setTranscription(result?.data || "");

      await sendChunck();
    }
  };

  const handleStopRecording = () => {
    sendChunck(true);
    setAudioChunks([]);
  };

  const startRecording = async () => {
    setTranscription("");

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder);

    recorder.ondataavailable = setChunk;
    recorder.onstop = handleStopRecording;

    recorder.start(100);
    setIsRecording(true);

    setTimeout(() => {
      sendChunck();
    }, 2000);
  };

  useEffect(() => {
    startRecording();
  }, []);

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();

      // Stop all tracks to release the microphone
      if (mediaRecorder.stream) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
      setChunkIndex(0);
      setShowPause(false);
    }
  };

  const sendToInfiniti = async () => {
    setIsRecording(false);

    await onSend("", { inputValue: transcription });

    stopRecording();
    setShowPause(true);
  };

  const closeRecording = () => {
    setAudioChunks([]);
    stopRecording();
    setIsRecording(false);
    setShowPause(true);
    setTranscription("");
  };

  useEffect(() => {
    audioChunksRef.current = audioChunks;
  }, [audioChunks]);

  const transcriptionText =
    typeof transcription === "string" &&
    transcription?.replace(/\s+/g, "")?.length; // Remove all whitespace characters like \n \t

  return (
    <Grid xs={12} sx={classes.AudioVizualizerContainer}>
      {transcriptionText ? (
        <Grid xs={12} sx={classes.TranscriptionContainer}>
          <pre
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              fontFamily: "inherit",
              margin: 0,
            }}
          >
            {transcription}
          </pre>
        </Grid>
      ) : null}
      <Grid xs={12} sx={classes.AudioVizualizerContent}>
        <Box>
          <IconButton onClick={closeRecording}>
            <CloseGreyCircle />
          </IconButton>
        </Box>
        <Box sx={classes.AudioVisualiser}>
          <AudioWave audioChunks={audioChunks} />
        </Box>
        <Box>
          {showPause ? (
            <IconButton onClick={stopRecording}>
              <PauseRecordingIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={sendToInfiniti}
              disabled={!transcriptionText}
              style={!transcriptionText ? { opacity: 0.5 } : {}}
            >
              <TickBlueCircle />
            </IconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

const withHOC = (STT) => {
  const Container = (props) => {
    const classes = STTStyles();
    const { children } = props;
    const [isRecording, setIsRecording] = useState(false);

    return (
      <Grid xs={12}>
        <Grid className={classes.SttContainer}>
          {!isRecording ? (
            <Grid xs={12}>
              {cloneElement(children, {
                startRecording: () => setIsRecording(true),
              })}
            </Grid>
          ) : (
            <STT {...props} setIsRecording={setIsRecording} />
          )}
        </Grid>
      </Grid>
    );
  };

  return Container;
};

STT.defaultProps = {
  classes: {},
};

STT.propTypes = {
  classes: PropTypes.object,
};

export const VoiceToText = withHOC(STT);
