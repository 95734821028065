import React, { useState, useRef, useEffect } from "react";
import { Transforms } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";
import {
  IconButton,
  Tooltip,
  Grid,
  Menu,
  MenuItem,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BackupIcon from "@mui/icons-material/Backup";
import {
  GridSettingsIcon,
  GridAddSectionIcon,
  WorkflowIcon,
} from "../../common/iconslist";
import FormPopup from "./FormPopup";
import ButtonPopup from "../Button/ButtonPopup";
import { formField } from "../../utils/formfield";
import { formSubmit } from "../../service/formSubmit";
import formButtonStyle from "../../common/StyleBuilder/formButtonStyle";
import Workflow from "./Workflow";
import { getTRBLBreakPoints, getBreakPointsValue } from "../../helper/theme";
import { validation } from "./FormElements/validations";
import Icon from "../../common/Icon";

const Form = (props) => {
  const { attributes, children, element, customProps } = props;
  const {
    readOnly,
    agency_id,
    site_id,
    page_id,
    onFormSubmit = () => {},
    tagName = "Pages",
    isIframe = false,
  } = customProps;
  const {
    buttonProps,
    textColor,
    formName,
    uid,
    borderWidth,
    borderColor,
    borderStyle,
    borderRadius,
    bannerSpacing,
    bgColor,
    formTitle,
    textSize,
    fontFamily,
    textAlign,
    alignment,
  } = element;

  const btnR = buttonProps?.borderRadius || {};
  const btnSpacing = buttonProps?.bannerSpacing || {};
  const btnAlign = buttonProps?.alignment || {};
  const btnM = buttonProps?.marginSpacing || {};
  const editor = useSlateStatic();
  const formEle = useRef();
  const [openSetttings, setOpenSettings] = useState(false);
  const [openWorkflow, setOpenWorkflow] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const [formHeight, setFormHeight] = useState();
  const [formWidth, setFormWidth] = useState();
  const path = ReactEditor.findPath(editor, element);

  useEffect(() => {
    setFormHeight(formEle?.current?.clientHeight);
    setFormWidth(formEle?.current?.clientWidth);
  }, []);

  const btnBorderStyle =
    buttonProps?.borderColor?.indexOf("linear") >= 0
      ? {
          borderImageSource: buttonProps?.borderColor,
          borderImageSlice: 1,
        }
      : {
          borderColor: buttonProps?.borderColor || "none",
        };

  const getFieldProps = (key = "", val = "") => {
    return element?.children?.find((obj) => obj && obj[key] === val);
  };

  const getFieldData = (key, type, field_type) => {
    return element?.children?.filter(
      (obj) =>
        obj &&
        obj?.hasOwnProperty(key) &&
        obj?.type === type &&
        obj?.[key]?.includes(field_type)
    );
  };

  const isMetaDataKey = (data, key) => {
    let metaData = data?.find((meta) => meta?.key === key);
    return metaData;
  };

  const handleSubmit = async (event, test = false) => {
    if (event) {
      event.preventDefault();
    }

    if ((readOnly || test) && formEle && formEle?.current) {
      const formData = new FormData(formEle?.current);
      setLoading(true);
      const response = [];

      let user_email = "";
      let meta_data = [];
      const validations = [];

      let metaFieldDataBoards = getFieldData(
        "element_metadatakey",
        "form-field",
        "board"
      );

      for (let pair of formData.entries()) {
        const emailObject = getFieldProps("element", "email");
        if (emailObject?.name === pair[0]) {
          user_email = pair[1];
        }
        const isMetaKey = isMetaDataKey(metaFieldDataBoards, pair[0]);

        if (isMetaKey) {
          meta_data.push({
            [isMetaKey?.element_metadatakey]: pair[1],
            type: "board",
            metadatamapping: element?.metadatamapping,
            tagName: tagName,
          });
        }

        const fieldData = getFieldProps("name", pair[0]);
        let rule = [];
        if (fieldData?.required) {
          rule.push(`isRequired`);
        }
        if (fieldData?.element === "email") {
          rule.push(`isEmail`);
        }
        if (fieldData?.required && fieldData?.element === "email") {
          validations.push({
            name: pair[0],
            value: pair[1],
            field_type: fieldData?.field_type,
            rules: rule?.length > 0 && rule,
          });
        }
        if (isMetaKey?.isrequired) {
          rule.push(`isRequired`);
          validations.push({
            name: pair[0],
            value: pair[1],
            field_type: isMetaKey?.placeholder,
            rules: rule?.length > 0 && rule,
          });
        }
        const placeholder =
          fieldData?.name === pair[0] ? fieldData?.placeholder : "";
        response?.push({
          fieldKey: pair[0],
          [pair[0]]: pair[1],
          placeholder: placeholder,
          form_name: formName,
          tagName: tagName,
        });
      }

      let params = {
        page_id: page_id,
        agency_id: agency_id,
        site_id: site_id,
        form_id: `${formName}`,
        uid: `${page_id}_${uid ? uid : formName}`,
        response: response,
        tagName: tagName,
        form_data: {
          user_email: user_email,
          email: element?.email,
          form_workflow: element?.workflow,
          save_response: element?.saveResponse,
        },
        meta_data: meta_data,
      };
      const isValidForm = validations.length !== 0 && validation(validations);
      if (isValidForm) {
        alert(isValidForm[0]);
      } else {
        const formRes = await formSubmit(params, customProps);
        if (formRes?.hasOwnProperty("form_id")) {
          onFormSubmit(formRes);
          setSubmittedSuccessfully(true);
          setAnchorEl(null);
        }
      }
      setLoading(false);
    }
  };

  const onSettings = () => {
    setOpenSettings(true);
  };

  const onSave = (data) => {
    const path = ReactEditor.findPath(editor, element);
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onAddFormField = () => {
    try {
      Transforms.insertNodes(
        editor,
        { ...formField() },
        { at: [...path, children.length] }
      );
    } catch (err) {
      console.log(err, "Add Field Error in Form Builder");
    }
  };

  const onDelete = () => {
    if (path) {
      Transforms.removeNodes(editor, { at: path });
    }
  };
  const onWorkflow = () => {
    setOpenWorkflow(true);
  };

  const closeWorkflow = () => {
    setOpenWorkflow(false);
  };

  const onSaveButtonSettings = (data) => {
    onSave({ buttonProps: { ...data } });
    onCloseButtonSettings();
  };

  const onCloseButtonSettings = () => {
    setAnchorEl(null);
    setEditButton(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = (menuName) => () => {
    switch (menuName) {
      case "edit":
        setEditButton(true);
        break;
      case "close":
        setEditButton(false);
        break;
      case "test":
        // test submit form
        handleSubmit(null, true);
        break;
      default:
        return;
    }
  };

  const onSubmitClick = (e) => {
    if (readOnly) {
      // submit the form
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const onMouseOver = () => {
    setShowOptions(true);
  };

  const handleCloseMessage = () => {
    setSubmittedSuccessfully(false);
  };

  const onMouseLeave = () => {
    setShowOptions(false);
  };

  const FormToolbar = () => {
    return (
      <div
        className="element-toolbar hr"
        contentEditable={false}
        style={{ top: "-42px", left: "0px", textAlign: "left" }}
      >
        <Tooltip title="Form Settings" arrow>
          <IconButton onClick={onSettings} className="mr">
            <GridSettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Form Field" arrow>
          <IconButton onClick={onAddFormField} className="mr">
            <GridAddSectionIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Form" arrow>
          <IconButton onClick={onDelete} className="mr">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Workflow" arrow>
          <IconButton className="svg-big-btn" onClick={onWorkflow}>
            <WorkflowIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <div
      {...attributes}
      className="form-wrapper element-root"
      style={{
        border: !readOnly ? "none" : "none",
        padding: "10px",
        width: "100%",
      }}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
        <Box
          component={"form"}
          id={`${formName}`}
          onSubmit={handleSubmit}
          sx={{
            color: textColor || "#FFF",
            borderColor: borderColor || "transparent",
            borderWidth: borderWidth || "1px",
            display: submittedSuccessfully ? 'none' : 'block',
            borderRadius: {
              ...getBreakPointsValue(
                borderRadius || {},
                null,
                "overrideBorderRadius",
                true
              ),
            },
            borderStyle: borderStyle || "solid",
            background: bgColor || "transparent",
            padding: {
              ...getTRBLBreakPoints(bannerSpacing),
            },
            position: "relative",
          }}
          ref={formEle}
        >
          <Grid className="form-grid" item xs={12}>
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: alignment?.horizantal || "start",
              }}
            >
              <legend
                style={{
                  fontSize: `${textSize}px` || "inherit",
                  fontFamily: fontFamily || "PoppinsRegular",
                  textAlign: textAlign || "left",
                  width: "100%",
                }}
              >
                {formTitle}
              </legend>
              {children}
              <Grid
                item
                {...buttonProps?.grid}
                className="form-btn-wrpr"
                contentEditable={false}
                style={{
                  display: "flex",
                  justifyContent: btnAlign?.horizantal || "start",
                  alignItems: btnAlign?.vertical || "start",
                }}
              >
                <Box
                  component={"button"}
                  onClick={onSubmitClick}
                  disabled={loading}
                  sx={{
                    background: buttonProps?.bgColor || "rgb(30, 75, 122)",
                    borderWidth: "1px",
                    borderBlockStyle: "solid",
                    ...btnBorderStyle,
                    borderRadius: {
                      ...getBreakPointsValue(
                        btnR || {},
                        null,
                        "overrideBorderRadius",
                        true
                      ),
                    },
                    padding: {
                      ...getTRBLBreakPoints(btnSpacing),
                    },
                    margin: {
                      ...getTRBLBreakPoints(btnM),
                    },
                    color: `${buttonProps?.textColor || "#FFFFFF"}`,
                    fontSize: buttonProps?.textSize || "inherit",
                    height: "fit-content",
                    fontFamily: buttonProps?.fontFamily || "PoppinsRegular",
                    width: buttonProps?.fullWidth ? "100%" : "auto",
                  }}
                >
                  {buttonProps?.label || "Submit"}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {!readOnly && showOptions && <FormToolbar />}
          {loading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(255,255,255,0.5)",
              }}
            >
              <CircularProgress
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: "auto",
                }}
              />
            </div>
          )}
        </Box>
      <Grid item sx={{ display: submittedSuccessfully ? 'flex' : 'none', }} contentEditable={false}>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}
        >
          <Grid item>
            <Typography
              sx={{
                color: textColor || "#A2A9B4",
                borderColor: borderColor || "transparent",
                borderWidth: borderWidth || "1px",
                borderRadius: {
                  ...getBreakPointsValue(
                    borderRadius || {},
                    null,
                    "overrideBorderRadius",
                    true
                  ),
                },
                borderStyle: borderStyle || "solid",
                background: bgColor || "transparent",
                padding: {
                  ...getTRBLBreakPoints(bannerSpacing),
                },
                minHeight: `${formHeight}px`,
                minWidth: `${formWidth}px`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                position: "relative",
              }}
            >
              Form Submitted Successfully...!
              <span style={{paddingLeft: "10px"}}><IconButton onClick={handleCloseMessage}>
                <Icon icon={"closeIcon"} />
              </IconButton></span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {openSetttings ? (
        <FormPopup
          element={element}
          onSave={onSave}
          onClose={onClose}
          customProps={customProps}
        />
      ) : null}
      <Workflow
        openWorkflow={openWorkflow}
        element={element}
        closeWorkflow={closeWorkflow}
        onSave={onSave}
      />
      {!readOnly && !isIframe ? (
        <Menu
          className="editor-btn-options"
          open={anchorEl !== null}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <MenuItem onClick={onMenuClick("edit")}>
            <Tooltip title="Button Settings" arrow>
              <IconButton>
                <GridSettingsIcon />
              </IconButton>
            </Tooltip>
          </MenuItem>
          <MenuItem onClick={onMenuClick("test")}>
            <Tooltip title="Test Submit" arrow>
              <IconButton>
                <BackupIcon />
              </IconButton>
            </Tooltip>
          </MenuItem>
        </Menu>
      ) : null}
      {editButton && (
        <ButtonPopup
          element={buttonProps || {}}
          onSave={onSaveButtonSettings}
          onClose={onCloseButtonSettings}
          customProps={customProps}
          style={formButtonStyle}
          styleName={"formButtonStyle"}
        />
      )}
    </div>
  );
};

export default Form;
