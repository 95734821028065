import React, { useEffect, useMemo, useState } from "react";
import { Grid, TextField, InputAdornment, Typography } from "@mui/material";
import ColorPickerButton from "../../ColorPickerButton";
import { useSlateStatic } from "slate-react";
import { getElementStyle, getVariableValue } from "../../../helper/theme";

const MAP_STYLE_PROPS = {
  bgColor: "background-color",
  textColor: "color",
};

const Color = (props) => {
  const {
    value: val,
    data,
    onChange,
    classes,
    elementProps,
    handleClose,
    customProps,
  } = props;
  const { key, label } = data;
  const [recentColors, setRecentColors] = useState({});
  const editor = useSlateStatic();
  const { value, inputValue } = useMemo(() => {
    const isVariable = val && val.startsWith("var");

    const value = isVariable
      ? val
      : getElementStyle(editor, elementProps, MAP_STYLE_PROPS[key]);

    const inputValue = isVariable
      ? getVariableValue(val)
      : getElementStyle(editor, elementProps, MAP_STYLE_PROPS[key]);

    return {
      value: value || val,
      inputValue: inputValue || val,
    };
  }, [editor, val]);

  useEffect(() => {
    const storedColors = JSON.parse(localStorage.getItem("recentColors"));
    if (storedColors) {
      setRecentColors(storedColors);
    }
  }, []);

  const onSave = (color) => {
    onChange({
      [key]: color,
    });

    let updatedColors = [];

    if (recentColors[key]) {
      updatedColors = [color, ...recentColors[key]?.filter((c) => c !== color)];
    } else {
      updatedColors?.unshift(color);
    }
    if (updatedColors?.length > 10) {
      updatedColors?.pop();
    }
    updatedColors = { ...recentColors, [key]: updatedColors };
    setRecentColors(updatedColors);
    localStorage.setItem("recentColors", JSON.stringify(updatedColors));
  };

  return (
    <Grid item xs={12} className="btnColorPicker">
      <Typography
        variant="body1"
        color={"primary"}
        sx={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px" }}
      >
        {label}
      </Typography>
      <TextField
        fullWidth
        value={inputValue}
        placeholder={`${label} color code`}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ColorPickerButton
                classes={classes}
                value={value}
                onSave={onSave}
                recentColors={recentColors[key]}
                handleClose={handleClose}
                disableEditTheme={customProps?.disableEditTheme}
              />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

export default Color;
