const Style = () => ({
  ".fs-12": { fontSize: "12px" },
  ".fs-14": { fontSize: "14px" },
  ".fw-500": { fontWeight: "500" },
  ".fw-600": { fontWeight: "600" },
  ".pointer": { cursor: "pointer" },
  ".transformNone": { textTransform: "none" },
  ".settingsContainer": {
    width: "340px",
    padding: "16px 5px",
    background: "#fff",
    ".header": {
      borderBottom: `1px solid #DCE4EC`,
      paddingBottom: "16px",
      marginBottom: "16px",
    },
  },
  ".title": {
    fontWeight: "700",
    fontSize: "14px",
  },
  ".closeBtn": {
    width: "26px",
    height: "26px",
    BorderRadius: "4px",
    flexShrik: "0",
    padding: "4px",
    background: " #F8FAFC",
    borderRadius: "4px",
  },
  ".activeTheme": {
    background: "#F4F4F6",
    padding: "14px 10px",
    borderRadius: "4px",
  },
  ".activeThemeInnner": {
    borderRadius: "7px",
    padding: "16px",
    boxShadow: "1px 2px 5px 0px #00000014",
    background: "#fff",
  },
  ".flexAlign": {
    display: "flex",
    alignItems: "center",
  },
  ".activeColorBox, .xsColorBox": {
    width: "24px",
    height: "24px",
    borderRadius: "5px",
    border: `1px solid #929292`,
  },
  ".themeCard": {
    borderRadius: "7px",
    padding: "16px",
    border: "1px solid #F3F3F3",
    boxShadow: "1px 2px 5px 0px #00000014",
    background: "white",
    flexWrap: "nowrap",
  },
  ".themeCardWrapper": {
    padding: "10px",
    borderRadius: "4px",
    cursor: "pointer",

    "&.active": {
      background: "#ECF2FF",
    },
  },
  ".primaryCard, .secondaryCard": {
    width: "44px",
    height: "44px",
    borderRadius: "5px",
    border: `1px solid #929292`,
  },
  ".otherColors": {
    display: "flex",
    flexWrap: "wrap",
    width: "48px",
    ".smallCard": {
      width: "20px",
      height: "20px",
      borderRadius: "5px",
      border: `1px solid #929292`,
    },
  },
  ".sectionDetail": {
    borderRadius: "4px",
    background: "#fff",
  },
  ".sectionTitle": {
    borderBottom: `1px solid #DCE4EC`,
    fontWeight: "600",
    fontSize: "14px",
  },
  // ".activeThemeInnner": {
  //   borderRadius: "7px",
  //   padding: "16px",
  //   boxShadow: "1px 2px 5px 0px #00000014",
  //   background: "#fff",
  // },
  ".activeWrapper": {
    background: "#ECF2FF",
    padding: "14px 10px",
    position: "relative",
    ".tickIcon": {
      position: "absolute",
      right: "5px",
      top: "8px",
    },
  },
  ".settingAccordion": {
    background: "#fff",
    border: "1px solid #F3F3F3",
    boxShadow: "1px 2px 5px 0px #00000014",
    borderRadius: "7px",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "0px 12px",
    "&:before": {
      background: "transparent",
    },
    ".MuiAccordionSummary-root ": {
      minHeight: "45px",
      padding: "0px",
      "&.Mui-expanded": {
        borderBottom: `1px solid #DCE4EC`,
      },
    },
    ".MuiAccordionSummary-content ": {
      padding: "12px 0px",
      margin: "0px !important",
      minHeight: "45px",
      fontSize: "14px",
      fontWeight: "600",
    },
    ".MuiAccordionDetails-root ": {
      padding: "12px 0px",
    },
  },
});

export default Style;
