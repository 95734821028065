const Styles = (theme) => ({
  aiContainer: {
    background: "#FCFAFF",
    border: "1px solid #8360FD",
    borderRadius: "6px",
    boxShadow: "0px 4px 10px 0px #00000029",
  },
  aiInputWrapper: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    padding: "0px 4px 0px 12px",
    minHeight: "36px",
    position: "relative",
    background: "#FCFAFF",
    borderRadius: "5px",

    "& .icon-container": {
      display: "flex",
      alignItems: "center",
    },

    "& .icons-elements": {
      height: "40px",
    },

    "& .ai-input": {
      padding: "8px 4px 8px 18px",
      border: "none",
      outline: "none",
      width: "100%",
      background: "transparent",
      resize: "none",
      alignSelf: "center",
      color: "#000",
      fontSize: "14px !important",
      fontFamily: '"Inter", sans-serif',

      "&::placeholder": {
        color: "#94A3B8",
        opacity: 1 /* Firefox */,
        fontFamily: '"Inter", sans-serif',
        fontWeight: 500,
      },

      "&::-ms-input-placeholder": {
        /* Edge 12-18 */ color: "#94A3B8",
        fontFamily: '"Inter", sans-serif',
      },
    },

    "& .action-btns": {
      display: "flex",
      alignItems: "center",
      gap: "6px",

      "& .MuiTypography-root": {
        color: "#64748B !important",
        fontSize: "14px",
        textTransform: "none",
      },

      "& .btn-wrapper": {
        display: "flex",
        alignItems: "center",
        gap: "6px",
      },
    },

    "& .loading-container": {
      display: "flex",
      alignItems: "center",
      color: "#2563EB !important",
      gap: "12px",
      width: "100%",
      paddingLeft: "14px",
      alignSelf: "center",

      "& .MuiTypography-body1": {
        fontSize: "14px",
        color: "#2563EB !important",
        fontWeight: 500,
      },
    },
  },
  sendBtn: {
    background: "linear-gradient(331.11deg, #2563EB 7.11%, #8360FD 88.37%)",
  },
  sendBtnDisabled: {
    background: "#C0C9D6",

    "&:hover": {
      background: "#C0C9D6",
    },
  },
  aiPopper: {
    zIndex: 9000,

    "& .MuiPaper-rounded": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  generatedText: {
    margin: "8px",
    overflow: "auto",
    fontSize: "inherit",
  },
  customSelectWrapper: {
    width: "fit-content",
    marginTop: "4px",

    "@media only screen and (max-width: 600px)": {
      marginBottom: "4px",
    },
  },
  customSelectContainer: {
    background: theme?.palette?.editor?.background,
    boxShadow: "0px 4px 10px 0px #00000029",
    borderRadius: "8px",
    maxHeight: "250px",
    overflow: "auto",
    // width: "240px",
    minWidth: "200px",
    border: "1px solid #E0E0E0",
    padding: "6px",
  },
  optionWrapper: {
    position: "relative",

    // "& .select-option": {
    //   position: "absolute",
    //   left: 0,
    //   top: 0
    // },
  },
  optionBtn: {
    color: theme?.palette?.editor?.textColor || "#373232",
    textTransform: "none",
    justifyContent: "space-between",
    width: "100%",
    fontWeight: "normal !important",
    minHeight: "36px",
    padding: "4px 8px",

    "& .option-label": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },

    "&:hover": {
      background: `${theme?.palette?.containers?.bg3 || "#E9F3FE"}`,
    },
    "&.active": {
      background: `${theme?.palette?.containers?.bg3 || "#E9F3FE"}`,
      color: "#2563EB",

      "& svg path": {
        stroke: "#2563EB",
      },
      "& svg": {
        color: "#2563EB !important",
      },
    },
  },
  optionHeading: {
    color: theme?.palette?.editor?.textColor,
    padding: "10px 0px 8px 4px",
    fontWeight: 600,
    borderBottom: "1px solid #DCE4EC",
    margin: "0px 8px 4px 8px",
  },
  mobileAIInputWrapper: {
    position: "fixed",
    bottom: "env(safe-area-inset-bottom)",
    left: 0,
    width: "100%",
    zIndex: "9000",
  },
  sendIconContainer: {
    alignSelf: "flex-end",
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
});

export default Styles;
