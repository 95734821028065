import { useSlate } from "slate-react";
import { defaultTheme } from "../theme";
import { getPageSettings, updatePageSettings } from "../utils/pageSettings";
import { HEADING_THEME_FIELDS, PARAGRAPH_THEME_FIELDS } from "../helper/theme";

const MAP_FIELDS = {
  HEADING: HEADING_THEME_FIELDS,
  PARAGRAPH: PARAGRAPH_THEME_FIELDS,
};

function getUpdatePayload(prevTheme = {}, update, actionData) {
  const { action, fieldName } = actionData || {};

  const { theme: prev } = prevTheme;

  let theme = {};
  let themeProps = {};

  switch (action) {
    case "THEME_CHANGE":
      theme = update;
      break;
    case "CSS_VAR_CHANGE":
      const prevCssVars = prev?.cssVars || {};
      const prevValue = prevCssVars[fieldName] || {};

      themeProps = {
        ...(prev || {}),
        cssVars: {
          ...prevCssVars,

          [fieldName]: {
            ...prevValue,
            ...update,
          },
        },
      };

      theme = {
        ...prevTheme,
        theme: themeProps,
      };
      break;
    case "ELEMENT_PROPS_CHANGE":
      const prevEleProps = prev?.elementProps || {};
      const prevEleValue = prevEleProps[fieldName] || {};

      themeProps = {
        ...(prev || {}),
        elementProps: {
          ...prevEleProps,

          [fieldName]: {
            ...prevEleValue,
            ...update,
          },
        },
      };

      theme = {
        ...prevTheme,
        theme: themeProps,
      };
      break;
    case "OTHER_PROPS_CHANGE":
      themeProps = {
        ...(prev || {}),
        otherProps: {
          ...(prev.otherProps || {}),
          ...update,
        },
      };

      theme = {
        ...prevTheme,
        theme: themeProps,
      };
      break;
    case "FONT_CHANGE":
      const { elementProps = {}, otherProps = {} } = prev;

      const fields = MAP_FIELDS[actionData?.fieldType];

      const headingUpdate = {};
      fields?.forEach((field) => {
        const prevHeadingData = elementProps[field];
        headingUpdate[field] = {
          ...prevHeadingData,
          ...update,
        };
      });

      themeProps = {
        ...(prev || {}),
        elementProps: {
          ...elementProps,
          ...headingUpdate,
        },
        otherProps: {
          ...otherProps,
          headingFontFamily: update?.fontFamily,
        },
      };

      theme = {
        ...prevTheme,
        theme: themeProps,
      };
      break;
    default:
      theme = {
        ...prevTheme,
        ...(update || {}),
      };
      break;
  }

  return theme;
}

const updateTheme = (editor, pageSt, update, actionData) => {
  const { pageProps } = pageSt || {};
  const { theme } = pageProps || {};

  updatePageSettings(editor, {
    ...(pageProps || {}),
    theme: getUpdatePayload(theme, update, actionData),
  });
};

export const useEditorTheme = () => {
  const editor = useSlate();
  const { element: pageSt } = getPageSettings(editor) || {};
  const { pageProps } = pageSt || {};
  const { theme } = pageProps || {};

  return {
    selectedTheme: theme?.theme || defaultTheme?.theme,
    updateTheme: (update, actionData) =>
      updateTheme(editor, pageSt, update, actionData),
    theme,
  };
};
