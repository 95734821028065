import React, { useMemo } from "react";
import {
  FormControl,
  Grid,
  ListItemIcon,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { getBreakPointsValue, getElementStyle } from "../../../helper/theme";
import useWindowResize from "../../../hooks/useWindowResize";
import { useSlateStatic } from "slate-react";

const TextOptions = (props) => {
  const {
    value: val,
    data,
    onChange,
    elementProps,
    classes,
    customProps,
  } = props;
  const { metaMappings } = customProps;
  const { key, isBreakpoint, options, renderOption, width, themeEnabled } =
    data;

  const [size] = useWindowResize();
  const editor = useSlateStatic();
  const value = useMemo(() => {
    const userValue = isBreakpoint
      ? getBreakPointsValue(val, size?.device)
      : val;

    if (themeEnabled) {
      // editor takes a little amount of time to update the new style in element, in that case userValue will be returned
      return getElementStyle(editor, elementProps, key) || userValue;
    }

    return userValue;
  }, [editor, isBreakpoint, val, size?.device]);

  const metaDataMappingOptions = metaMappings?.boards || [];
  const updatedOption = elementProps?.metadatamapping
    ? [...options, ...metaDataMappingOptions]
    : options;
  const handleChange = (e, d) => {
    if (isBreakpoint) {
      onChange({
        [key]: {
          ...getBreakPointsValue(val),
          [size?.device]: e.target.value,
        },
      });
    } else {
      onChange({
        [key]: e.target.value,
        [`${key}_metadatakey`]: d?.props?.item,
        isrequired: d?.props?.isrequired,
      });
    }
  };

  return (
    <>
      <Grid item xs={width || 12} style={{ marginBottom: "5px" }}>
        <Typography
          variant="body1"
          color={"primary"}
          sx={{ fontSize: "14px", fontWeight: "500", marginBottom: "4px" }}
        >
          {data?.label}
        </Typography>
        <FormControl
          sx={{
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
            },
          }}
          fullWidth
          size="small"
        >
          <Select
            onChange={handleChange}
            value={value || updatedOption[0]?.value}
            placeholder={data?.label}
            fullWidth
            size="small"
            style={{
              marginBottom: "16px",
            }}
            sx={classes.textOptions}
          >
            {updatedOption?.map((m, i) => {
              return m?.value === "listSubHeader" ? (
                <ListSubheader key={`subHeader_${i}`}>{m?.label}</ListSubheader>
              ) : (
                <MenuItem
                  key={`${key}_${i}`}
                  value={m?.value}
                  item={m?.metaDataKey}
                  isrequired={m?.isRequired?.toString()}
                >
                  {m?.icon && (
                    <ListItemIcon sx={{ minWidth: "25px" }}>
                      {m?.icon}
                    </ListItemIcon>
                  )}
                  {renderOption
                    ? renderOption(m, elementProps)
                    : m.label || m.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default TextOptions;
