import { useEffect, useRef, useState } from "react";
import { useEditorContext } from "../../hooks/useMouseMove";
import Styles from "./Styles";
import { Fade, Paper, Popper } from "@mui/material";
import AIInput from "./AIInput";
import { ReactEditor, useSlate } from "slate-react";
import { Node, Transforms } from "slate";
import { MODES } from "./helper";
import { getSelectedText } from "../../utils/helper";
import { VoiceToText } from "./VoiceToText";
import deserialize from "../../helper/deserialize";

const scrollToAIInput = (editor) => {
  try {
    setTimeout(() => {
      const slateWrapper = document.getElementById(
        "slate-wrapper-scroll-container"
      );

      let selectionRect;

      if (getSelectedText(editor)) {
        selectionRect = window
          .getSelection()
          .getRangeAt(0)
          .getBoundingClientRect();
      } else {
        selectionRect = ReactEditor.toDOMRange(
          editor,
          getNextLine(editor).at
        ).getBoundingClientRect();
      }

      const selectionScrollBottom = selectionRect.bottom;

      // if the cursor or selection top position is greater than 80
      if (selectionScrollBottom > 80) {
        // scroll to top of the slateWrapper
        slateWrapper.scrollTo({
          top: slateWrapper.scrollTop + selectionScrollBottom - 80,
          behavior: "smooth",
        });
      }
    }, 200);
  } catch (err) {
    console.log(err);
  }
};

const insertText = (editor, text, options) => {
  const parsed = new DOMParser().parseFromString(text, "text/html");
  const fragment = deserialize(parsed.body);

  Transforms.insertFragment(editor, fragment, options);
};

const insertAtNextLine = (editor, text) => {
  const nextLine = getNextLine(editor);

  insertText(editor, text, { at: nextLine.at });

  Transforms.splitNodes(editor, { at: nextLine.at });
};

const getNextLine = (editor) => {
  const { selection } = editor;
  const { focus } = selection;
  const { text = "" } = Node.get(editor, focus.path);

  let nextLineIndex = 0;
  let indexOfNextLine = 0;

  if (text?.length) {
    // split the text based on caret position
    const textBeforeCaret = text.substring(0, focus.offset);
    const textAfterCaret = text.substring(focus.offset);

    // getting the index of the next line after the caret position
    indexOfNextLine = textAfterCaret?.indexOf("\n");

    if (indexOfNextLine >= 0) {
      // index of next line
      nextLineIndex = textBeforeCaret?.length + indexOfNextLine;
    } else {
      nextLineIndex = text?.length;
    }
  }

  const data = {
    ...focus,
    offset: nextLineIndex,
  };

  const at = {
    anchor: data,
    focus: data,
  };

  return { at, indexOfNextLine };
};

const updateAnchorEl = (setAnchorEl, editor) => {
  try {
    if (!editor.selection) {
      return;
    }

    const selection = window.getSelection();
    if (selection.rangeCount) {
      let caret;

      if (getSelectedText(editor)) {
        // selected text as caret
        caret = selection.getRangeAt(0);
      } else {
        const domElement = ReactEditor.toDOMRange(
          editor,
          getNextLine(editor).at
        );

        const { textContent, parentElement } =
          domElement?.commonAncestorContainer || {};

        caret = textContent ? domElement : parentElement; // in mobile, if textContent in not available, it is pointing some <br> tag (getBoundingClientRect not working correctly for <br>), to avoid that, we are pointing the parent element as caret
      }

      const getBoundingClientRect = () => {
        const editorContainer = document
          .querySelector("#slate-wrapper-scroll-container")
          ?.getBoundingClientRect();

        const editorEle = document
          .querySelector(".ed-section-inner")
          ?.getBoundingClientRect();

        const caretPos = caret.getBoundingClientRect();

        const isAIInputReachTop =
          caretPos.height + caretPos.y <= editorContainer.y;

        const yValue = isAIInputReachTop ? "-500" : caretPos.y; // -500 is to hide the AI input if the toolbar reached the top

        return {
          y: yValue,
          height: caretPos.height,
          top: yValue,
          right: caretPos.right,
          bottom: caretPos.bottom,

          x: editorEle.x,
          left: editorEle.left,
          width: editorEle.width,
        };
      };

      setAnchorEl({ getBoundingClientRect });
    }
  } catch (err) {
    console.log(err);
  }
};

function PopoverAIInput({ otherProps }) {
  const { services } = otherProps;
  const { openAI, setOpenAI } = useEditorContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState();

  const classes = Styles();
  const editor = useSlate();

  const onClickOutside = () => {
    setAnchorEl(null);
    setOpenAI("");
    setGeneratedText("");
    setLoading(false);
    setSelectedOption(null);
    setInputValue("");
    ReactEditor.focus(editor);
    Transforms.deselect(editor);
  };

  const editorElement = document.querySelector(".ed-section-inner");

  useEffect(() => {
    updateAnchorEl(setAnchorEl, editor);
  }, [openAI, editor.selection]);

  useEffect(() => {
    if (openAI) {
      scrollToAIInput(editor);
    }
  }, [openAI]);

  const onSend = async (type, option) => {
    if (type === "close") {
      onClickOutside();
      return;
    }

    if (type === "done") {
      // Get the current selection point
      const { anchor } = editor.selection;

      const { path } = anchor;
      const { text: selectText } = Node.get(editor, path);

      if (selectText?.length) {
        insertAtNextLine(editor, generatedText);
      } else {
        insertText(editor, generatedText);
      }

      onClickOutside();
      return;
    }

    if (type === "replace_selection") {
      // replace generated text
      insertText(editor, generatedText);
      onClickOutside();
      return;
    }

    if (type === "speech_to_text") {
      setGeneratedText(option.text);
      return;
    }

    if (type === "try_again") {
      // resetting the previous option and try again
      option = selectedOption;
      type = selectedOption.value;
    } else {
      setSelectedOption(option);
    }

    setLoading(true);

    const payload = {
      mode: option.mode || 0,
      query: option?.inputValue || inputValue,
    };

    if (option.mode === MODES.translate || option.mode === MODES.rephraseTone) {
      payload.textOptionInput = type;
    }

    if (option.mode) {
      payload.textData = generatedText || window.getSelection().toString();
    }

    const result = await services("infinityAI", payload);

    setLoading(false);
    setInputValue("");

    let { data: text } = result || {};

    if (!text) {
      onClickOutside();
      return;
    }

    if (!option.replace) {
      if (type === "continue_writing") {
        setGeneratedText(generatedText + text);
      } else {
        setGeneratedText(text);
      }

      return;
    }

    insertText(editor, text);

    // scrollToAIInput();
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div>
      <Popper
        open={Boolean(openAI)}
        anchorEl={anchorEl}
        transition
        placement="bottom-start"
        sx={{
          ...classes.aiPopper,
          width: editorElement?.offsetWidth || 400,
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={getSelectedText(editor) ? { marginTop: "6px" } : {}}>
              <VoiceToText otherProps={otherProps} onSend={onSend}>
                <AIInput
                  loading={loading}
                  onSend={onSend}
                  generatedText={generatedText}
                  anchorEl={anchorEl}
                  openAI={openAI}
                  inputValue={inputValue}
                  onInputChange={onInputChange}
                  onClickOutside={onClickOutside}
                />
              </VoiceToText>
            </Paper>
          </Fade>
        )}
      </Popper>

      {/* virutal height for scrolling when ai input is opened */}
      {openAI ? (
        <div
          style={{
            height: "100vh",
            background: "transparent",
          }}
        ></div>
      ) : null}
    </div>
  );
}

export default PopoverAIInput;
