import React, { useState, useEffect } from "react";
import { Editor, Element, Transforms } from "slate";
import { Box, MenuItem, Popper, Typography } from "@mui/material";
import { useSlateStatic, ReactEditor } from "slate-react";
import useTableResize from "../../utils/customHooks/useTableResize";
import { TableUtil } from "../../utils/table";
import TableStyles from "./Styles";
import { useEditorSelection } from "../../hooks/useMouseMove";
import useClickOutside from "../../hooks/useClickOutside";

const Resizer = ({ classes, onMouseDown, height }) => {
  return (
    <>
      <Box
        component={"div"}
        className="cell-resizer"
        contentEditable={false}
        onPointerDown={onMouseDown}
        sx={classes.cellResizer}
        style={{ height: `${height}px` }}
      />
    </>
  );
};

const TableCell = (props) => {
  const themeType = localStorage.getItem("themeType");
  const classes = TableStyles(themeType);
  const { element, attributes, children, customProps } = props;
  const { readOnly } = customProps;
  const { bgColor, borderColor, entireBgColor, entireBorderColor } = element;
  const [parentDOM, setParentDOM] = useState(null);
  const editor = useSlateStatic();
  const [showTool] = useEditorSelection(editor);
  const path = ReactEditor.findPath(editor, element);
  const isHeader = path.length >= 2 ? path[path.length - 2] === 0 : false;
  const [size, onMouseDown, resizing, onLoad] = useTableResize({
    parentDOM,
    size: element?.size,
  });
  const [tableSize, setTableSize] = useState({});
  const table = new TableUtil(editor);
  const tableProps = table.getTableProps();
  const [tableNode] = Editor.nodes(editor, {
    at: path,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
  });
  const [rowNode] = Editor.nodes(editor, {
    at: path,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "table-row",
  });
  const [parentProps] = tableNode || [{}];
  const [rowProps] = rowNode || [{}];
  const tableDOM = table.getDOMNode(path, true);
  const isCellSelected = table.isCellSelected(editor.selection);
  const hasSelected =
    (isCellSelected || [])?.findIndex((f) => f.join(",") === path.join(",")) >
    -1;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popperOptions = [{ value: "Delete Row" }, { value: "Delete Column" }];

  const onRightClick = (e) => {
    e.preventDefault();
    if (hasSelected) {
      setAnchorEl(e.currentTarget);
    }
  };

  const closePoper = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    Transforms.select(editor, editor.selection);
    switch (value) {
      case "Delete Row":
        table.deleteRow();
        break;
      case "Delete Column":
        table.deleteColumn();
        break;
      default:
        return;
    }
    // closePoper();
  };

  // Use the useClickOutside hook
  const popperRef = useClickOutside({
    onClickOutside: closePoper,
    refCount: 1,
  })[0];

  useEffect(() => {
    if (tableDOM) {
      const { width, height } = tableDOM.getBoundingClientRect();
      const { width: parentWidth } =
        tableDOM?.parentNode?.parentNode?.getBoundingClientRect();
      const { styleProps } = tableProps || {};
      const columns = styleProps ? styleProps["table.columns"] : 2;
      setTableSize({
        width,
        height,
        parentWidth,
        cellWidth: parentWidth / columns,
      });
    }
  }, [tableDOM, anchorEl]);

  useEffect(() => {
    if (editor && element && tableSize) {
      const dom = ReactEditor.toDOMNode(editor, element);
      setParentDOM(dom);
      onLoad(
        element?.size || {
          width: tableSize?.cellWidth,
          height: 100,
        }
      );
    }
  }, [tableSize, anchorEl]);

  useEffect(() => {
    if (!resizing && tableProps) {
      table.updateTableStyle({ "col.size": size }, tableProps);
    }
  }, [resizing, anchorEl]);

  const poperForDeltion = () => {
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
        ref={popperRef}
        sx={classes.deleteCellsPopUp}
      >
        {popperOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => handleMenuItemClick(option.value)}
            contentEditable={false}
            sx={classes.menuItemPopUp}
          >
            <Typography
              sx={{
                fontSize: "12px",
                userSelect: "none",
              }}
            >
              {option.value}
            </Typography>
          </MenuItem>
        ))}
      </Popper>
    );
  };

  const sizeProps = isHeader
    ? { width: size?.width || tableSize?.cellWidth }
    : {};
  const cellBorderColor =
    borderColor ||
    rowProps?.borderColor ||
    parentProps?.borderColor ||
    entireBorderColor;
  return (
    <td
      {...element.attr}
      {...attributes}
      className={`editor-table-cell ${hasSelected ? "selection" : ""}`}
      style={{
        position: "relative",
        background: bgColor || entireBgColor,
        border: `3px solid ${cellBorderColor}`,
        ...(sizeProps || {}),
      }}
      onContextMenu={onRightClick}
    >
      {children}
      {isHeader && !readOnly && tableSize?.height && !showTool ? (
        <Resizer
          classes={classes}
          onMouseDown={onMouseDown}
          height={tableDOM.getBoundingClientRect()?.height}
        />
      ) : null}
      {hasSelected && !readOnly ? (
        <div className="selection-area-tc" contentEditable={false} />
      ) : null}
      {poperForDeltion()}
    </td>
  );
};

export default TableCell;
