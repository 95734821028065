import { Editor, Node, Transforms, Element } from "slate";
import { ReactEditor } from "slate-react";
import insertNewLine from "./insertNewLine";
import { getDevice } from "../helper/theme";

export const windowVar = {};

export const formatDate = (date, format = "MM/DD/YYYY") => {
  if (!date) return "";
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  switch (format) {
    case "MM/DD/YYYY":
      return [month, day, year].join("/");
    case "YYYY-MM-DD":
      return [year, month, day].join("-");
    default:
      return [year, month, day].join("-");
  }
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    } else {
      resolve("");
    }
  });
};

export const gradientBorder = (color) => {
  if (color?.indexOf("linear") > -1) {
    return {
      borderImage: `${color} 30`,
    };
  } else {
    return {
      borderColor: color || "transparent",
    };
  }
};

export const getBorderColor = (color, borderWidth = 3) => {
  if (color?.indexOf("linear") > -1) {
    return {
      borderImage: `${color} ${borderWidth}`,
      borderWidth: `0px 0px 0px ${borderWidth}px`,
      borderStyle: "solid",
    };
  } else {
    return {
      borderColor: color || "transparent",
    };
  }
};

export const absoluteLink = (url) => {
  try {
    if (url?.indexOf("://") === -1) {
      return `//${url}`;
    }
    return url;
  } catch (err) {
    console.log(err);
    return url;
  }
};

export const isTextSelected = (selection) => {
  try {
    const { anchor, focus } = selection || {};
    if (anchor && focus) {
      if (JSON.stringify(anchor?.path) !== JSON.stringify(focus?.path)) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getSelectedText = (editor) => {
  try {
    return Editor.string(editor, editor?.selection);
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const isEmptyNode = (editor, children, path) => {
  try {
    const isEmptyText = Node.string(Node.get(editor, path))?.length === 0;
    const emptyNode =
      children?.length === 1 &&
      children &&
      children[0]?.children[0]?.type === undefined &&
      children[0]?.type === "paragraph";
    return isEmptyText && emptyNode;
  } catch (err) {
    // console.log(err);
    return "";
  }
};

export const outsideEditorClickLabel = "handle-outside-editor-click";

export const handleInsertLastElement = (event, editor) => {
  if (event.target.dataset.info !== outsideEditorClickLabel) {
    return;
  }

  const lastElement = editor.children[editor.children?.length - 1];

  const isLastElementEmpty =
    lastElement.type === "paragraph" &&
    !lastElement.children[0]?.text &&
    !lastElement.children?.some((c) => c.type === "grid");

  if (!ReactEditor.isFocused(editor)) {
    if (isLastElementEmpty) {
      // just focus on the last empty element
      const path = [editor.children.length - 1, 0];
      const move = {
        path: path,
        offset: 0,
      };

      Transforms.insertNodes(
        editor,
        {
          text: "",
        },
        {
          at: path,
        }
      );

      Transforms.move(editor, move);

      Transforms.select(editor, move);
    } else {
      // insert an new empty element and focus
      Transforms.insertNodes(
        editor,
        [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ],
        { at: [editor.children.length], select: true }
      );
    }

    ReactEditor.focus(editor);
  }
};

export const isListItem = (editor) => {
  const format = ["list-item", "check-list-item", "accordion-summary", "headingOne", "headingTwo", "headingThree"];

  const [node] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      format.indexOf(n.type) > -1,
  });

  return node;
};

const getNode = (editor, path) => {
  try {
    return Node.get(editor, path);
  } catch (err) {
    return;
  }
};

export const customInsertNode = (
  editor,
  insertNode,
  defaultInsertOptions = {}
) => {
  const [parent, parentPath] = Editor.parent(
    editor,
    editor.selection.focus.path
  );

  const isListItem =
    parent?.type === "list-item" || parent?.type === "check-list-item";

  let newParentPath;

  if (isListItem) {
    const lastPathIndex = parentPath.length - 1;
    const otherPaths = parentPath.slice(0, lastPathIndex);
    const nextChildrenPath = parentPath[lastPathIndex] + 1;
    newParentPath = [...otherPaths, nextChildrenPath];

    const haveElem = getNode(editor, newParentPath);

    if (haveElem) {
      Transforms.splitNodes(editor, { at: newParentPath });
    }

    const { anchor, focus } = editor.selection;

    // if editor has selection, e.g /table, /grid is selected, delete that selection
    if (focus.offset > anchor.offset) {
      Transforms.delete(editor, { at: editor.selection });
    }
  }

  const insertOptions = { ...defaultInsertOptions };

  if (isListItem) {
    insertOptions.at = editor.selection.focus;
  }

  Transforms.insertNodes(editor, insertNode, insertOptions);
  insertNewLine(editor);
};

export const decodeAndParseBase64 = (encodedString) => {
  // Decode the Base64-encoded string
  const decodedString = atob(encodedString);

  // URL-decode the decoded string
  const decodedURLString = decodeURIComponent(decodedString);

  const jsonData = JSON.parse(decodedURLString);

  return jsonData;
};

export const hasVerticalScrollbar = (element = {}) => {
  return element.scrollHeight > element.clientHeight;
};

const isHomePage = (page) => {
  return (
    page === "home" ||
    page === "iframe.html" ||
    page === "_currentPage" ||
    !page
  );
};

const getScrollElement = () => {
  const slateWrapper = document.getElementById(
    "slate-wrapper-scroll-container"
  );

  const isSlateWrapperScroll = hasVerticalScrollbar(slateWrapper);
  const scrollFrom = isSlateWrapperScroll ? slateWrapper : window;

  return scrollFrom;
};

const handleLinkBtnClick = (e, props) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }

  if (props.target) {
    window.open(props.href);
  } else {
    window.location.href = props.href;
  }
};

export const handleLinkType = (
  url,
  linkType,
  readOnly,
  openInNewTab,
  onClick = () => {}
) => {
  const props = {};

  if (!readOnly) {
    return {
      component: "button",
    };
  }

  linkType = getLinkType(linkType, url);

  switch (linkType) {
    case "webAddress":
      const refUrl = url ? (url.includes("http") ? url : `//${url}`) : "Link";
      props.component = "a";
      if (refUrl !== "Link") {
        props.href = refUrl;
      }

      if (openInNewTab) {
        props.target = "_blank";
      }

      break;
    case "nextTrigger":
    case "prevTrigger":
      if (!readOnly) {
        props.component = "button";
        props.onClick = () => { };
      } else {
        props.component = "button";
        props.onClick = onClick;
      }
      break;
    case "page":
      const [page = "", section] = url?.split("#") || [];
      const sec = section ? `#${section}` : "";

      if (page === "_currentPage") {
        props.component = "button";

        props.onClick = () => {
          const scrollFrom = getScrollElement();

          if (sec) {
            const element = document.getElementById(section);

            if (element) {
              const topPosition =
                element.getBoundingClientRect().top + scrollFrom.scrollTop;

              scrollFrom.scrollTo({
                top: topPosition,
                behavior: "smooth",
              });
            }
          }
        };
      } else {
        props.component = "a";

        const currentUserPage = getCurrentUserPage();

        props.href = isCurrentPage(page)
          ? `./${currentUserPage}${sec}`
          : `./${url}`;

        if (openInNewTab) {
          if (isCurrentPage(page)) {
            // temp fix, if user is presented in current page, open in new tab option is restricted, we will scroll to the element in current page
          } else {
            props.target = "_blank";
          }
        }
      }
      break;
    case "email":
      props.component = "a";
      props.href = `mailto:${url}`;
      break;
    case "phone":
      props.component = "a";
      props.href = `tel:${url}`;
      break;
    case "scrollTopOrBottom":
      props.component = "button";
      props.onClick = () => {
        const slateWrapper = document.getElementById(
          "slate-wrapper-scroll-container"
        );

        const isSlateWrapperScroll = hasVerticalScrollbar(slateWrapper);
        const scrollFrom = isSlateWrapperScroll ? slateWrapper : window;

        if (url === "top") {
          // top of the page
          scrollFrom.scrollTo(0, 0);
        } else if (url === "bottom") {
          const pageHeight = isSlateWrapperScroll
            ? slateWrapper.scrollHeight
            : document.body.scrollHeight;

          // bottom of the page
          scrollFrom.scrollTo(0, pageHeight);
        }
      };
      break;
    default:
      return {};
  }

  // for iphone fix
  if (props.component === "a" && props.href) {
    const isMobile = getDevice(window.innerWidth) === "xs";
    if (isMobile) {
      props.component = "button"; // iphone is opening two tabs, on open in new tab because of a tag.
    }

    let touchEndClicked = false;
    props.onTouchEnd = (e) => {
      touchEndClicked = true;
      handleLinkBtnClick(e, props);
    };

    props.onClick = (e) => {
      // This condition is used for mobile preview in the page editor.
      // 'touchEnd' will not work in the mobile page preview.
      if (!touchEndClicked && isMobile) {
        handleLinkBtnClick(e, props);
      }

      return false;
    };
  }

  return props;
};

const getCurrentUserPage = () => {
  const paths = window.location.pathname.split("/");
  let currentUserPage = paths[paths?.length - 1];

  return currentUserPage;
};

const isCurrentPage = (page) => {
  let currentUserPage = getCurrentUserPage();
  currentUserPage = isHomePage(currentUserPage) ? "home" : currentUserPage;

  const buttonPage = isHomePage(page) ? "home" : page;

  return currentUserPage === buttonPage;
};

export const getLinkType = (linkType, url) => {
  if (!linkType && url) {
    if (url?.includes("http")) {
      linkType = "webAddress";
    } else {
      linkType = "page";
    }
  }

  return linkType;
};

export const allowedDomains = [
  "youtube.com",
  "lemcal.com",
  "facebook.com",
  "calendly.com",
];

export const encodeString = (str) => {
  try {
    if (str) {
      return btoa(str);
    }
  } catch (err) {
    console.log(err);
  }
};

export const decodeString = (str) => {
  try {
    if (str) {
      return atob(str);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getContrastColor = (color) => {
  let r, g, b;

  // Check if the color is in hex format
  if (color.startsWith("#")) {
    r = parseInt(color.substring(1, 3), 16);
    g = parseInt(color.substring(3, 5), 16);
    b = parseInt(color.substring(5, 7), 16);
  }
  // Check if the color is in RGB/RGBA format
  else if (color.startsWith("rgb")) {
    const rgbValues = color
      .replace(/^rgba?\(|\s+|\)$/g, "") // Remove the rgb/rgba and spaces
      .split(","); // Split the values into an array

    r = parseInt(rgbValues[0]);
    g = parseInt(rgbValues[1]);
    b = parseInt(rgbValues[2]);
  } else {
    // If the format is not recognized, default to black text
    return "#000000";
  }

  // Calculate relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black for light colors, white for dark colors
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}

export const onDeleteKey = (event, { editor }) => {
  try {
    const { selection } = editor;
    if (selection) {
      // If text is selected, delete the selection
      Transforms.delete(editor);
    } else {
      // If no text is selected, handle deleting the next character/element
      Transforms.delete(editor, { unit: "character", reverse: false });
    }
  } catch (err) {
    console.log(err);
  }
};
