import { Grid, IconButton, Typography } from "@mui/material";
import { useEditorTheme } from "../../hooks/useEditorTheme";
import { PenIcon } from "../icons";
import useWindowResize from "../../hooks/useWindowResize";

const MAP_TEXT_PLACEHOLDER = {
  h1: "Heading 1",
  h2: "Heading 2",
  h3: "Heading 3",
  h4: "Heading 4",
  h5: "Heading 5",
  h6: "Heading 6",
  para1: "Paragraph 1",
  para2: "Paragraph 2",
  para3: "Paragraph 3",
};

const MAP_COMP = {
  para1: "p",
  para2: "p",
  para3: "p",
};

function getPreviewElementStyle(styleProperty, elementId) {
  const element = document.getElementById(elementId);

  if (element) {
    const computedStyle = window.getComputedStyle(element);
    return computedStyle.getPropertyValue(styleProperty) || "";
  }
}

function getTextSettings(elementId, otherStyles = {}) {
  return {
    fontFamily: getPreviewElementStyle("font-family", elementId),
    fontSize: getPreviewElementStyle("font-size", elementId),
    fontWeight: getPreviewElementStyle("font-weight", elementId),
    fontStyle: getPreviewElementStyle("font-style", elementId),
    textDecoration: getPreviewElementStyle("text-decoration", elementId),
    ...otherStyles,
  };
}

function getFontSize(fontSize = "", windowSize) {
  let size = "";

  if (typeof fontSize === "string") {
    size = fontSize;
  } else if (typeof fontSize === "object") {
    size = fontSize[windowSize?.device];
  }

  return size?.includes("px") ? size : size + "px";
}

function PreviewElement(props) {
  const {
    field,
    selectedField,
    setSelectedField,
    settings,
    setSettings,
    setAnchorEl,
  } = props;

  const { selectedTheme } = useEditorTheme();
  const { elementProps = {} } = selectedTheme || {};

  const previewElementId = `theme-heading-element-preview-${field}`;
  const Comp = MAP_COMP[field] || field;
  const selectedSettings = selectedField === field ? settings : {};
  const themeStyles = elementProps[field] || {};
  const placeholder = MAP_TEXT_PLACEHOLDER[field];
  const [windowSize] = useWindowResize();

  const fontFamily = getPreviewElementStyle("font-family", previewElementId);

  const { fontSize = "", color } = themeStyles;

  const size = getFontSize(fontSize, windowSize);

  return (
    <Grid
      container
      className="fontTypeItem"
      justifyContent={"space-between"}
      alignItems={"center"}
      wrap="nowrap"
    >
      <Grid item>
        <Typography variant="body1">
          <Comp
            id={previewElementId}
            style={{
              ...themeStyles,
              ...selectedSettings,
              fontSize: size,
            }}
            className="preview-theme-text"
          >
            {placeholder}
          </Comp>
        </Typography>
        <Typography variant="body2" className="flexAlign typeValue">
          <Grid
            component={"span"}
            className="dotColor"
            style={{
              background: selectedSettings?.color || color,
            }}
          ></Grid>
          <Grid component={"span"}>
            {fontFamily},{size}
          </Grid>
        </Typography>
      </Grid>
      <IconButton
        className="editIcon"
        onClick={(e) => {
          setSettings(getTextSettings(previewElementId, { color }));
          setSelectedField(field);
          setAnchorEl(e.currentTarget);
        }}
      >
        <PenIcon />
      </IconButton>
    </Grid>
  );
}

export default PreviewElement;
