const Style = () => ({
  ".settingAccordion": {
    ".MuiOutlinedInput-root": {
      borderRadius: "8px",
      boxShadow: "0px 4px 16px 0px #0000000D",
    },
  },
  ".fontTypeItem": {
    padding: "6px 10px",
    width: "calc(100% + 20px)",
    margin: "0 -10px",
    "&:hover": {
      background: "#ECF2FF",
      ".editIcon": {
        opacity: "1",
        visibility: "visible",
      },
    },
    ".editIcon": {
      opacity: "0",
      visibility: "hidden",
      width: "30px",
      height: "30px",
    },
  },
  ".typeValue": {
    fontSize: "12px",
    color: "#64748B",
    ".dotColor": {
      width: "12px",
      height: "12px",
      borderRadius: "12px",
      background: "#0F172A",
      marginRight: "3px",
    },
  },
  ".preview-theme-text": {
    width: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export default Style;
