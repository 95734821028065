const Style = () => ({
  ".buttonTypeItem": {
    padding: "8px 10px",
    width: "calc(100% + 20px)",
    margin: "0 -10px",
    "&:hover": {
      background: "#ECF2FF",
      ".editIcon": {
        opacity: "1",
        visibility: "visible",
      },
    },
    ".editIcon": {
      opacity: "0",
      visibility: "hidden",
      width: "30px",
      height: "30px",
    },
  },
});

export default Style;
