export const TickIcon = () => (
  <svg
    className="tickIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <rect
      x="0.5"
      y="0.5"
      width="25"
      height="25"
      rx="12.5"
      fill="#2563EB"
      stroke="white"
    />
    <path
      d="M7 13.105L11.0011 17.21L19 9"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditIcon = () => (
  <svg
    className="editIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle cx="8" cy="8" r="8" fill="#2563EB" fillOpacity="0.14" />
    <path
      d="M11.3066 4.69573C10.3941 3.77831 9.50211 3.75792 8.5642 4.69573L7.98821 5.26658C7.93723 5.31754 7.92194 5.3889 7.94233 5.45516C8.29914 6.70387 9.29822 7.70284 10.5471 8.05962C10.5623 8.06472 10.5878 8.06472 10.6031 8.06472C10.6541 8.06472 10.7051 8.04433 10.7408 8.00865L11.3066 7.43781C11.7704 6.974 11.9998 6.53058 11.9998 6.07697C11.9998 5.61825 11.7704 5.16464 11.3066 4.69573Z"
      fill="#2563EB"
    />
    <path
      d="M9.69545 8.48831C9.55783 8.42206 9.4253 8.3558 9.30296 8.27935C9.20102 8.21819 9.09907 8.15193 9.00223 8.08058C8.92067 8.02961 8.82892 7.95316 8.73717 7.87671C8.72697 7.87161 8.69639 7.84613 8.65561 7.80535C8.4976 7.67793 8.32939 7.50464 8.17137 7.31606C8.16118 7.30587 8.1306 7.27529 8.10511 7.22942C8.05414 7.17336 7.97768 7.07652 7.91141 6.96949C7.85534 6.89813 7.78908 6.7962 7.72791 6.68916C7.65145 6.56175 7.58519 6.43433 7.52402 6.30181C7.45776 6.1591 7.40679 6.02659 7.36091 5.89917L4.61859 8.64122C4.44019 8.8196 4.26688 9.15599 4.2312 9.40573L4.01202 10.9246C3.96614 11.2457 4.0528 11.5464 4.25159 11.7451C4.4198 11.9133 4.64917 12 4.90404 12C4.96011 12 5.01618 11.9949 5.07225 11.9898L6.58613 11.7757C6.83589 11.74 7.17231 11.5718 7.35072 11.3884L10.093 8.64631C9.96561 8.60554 9.83818 8.54948 9.69545 8.48831Z"
      fill="#2563EB"
    />
  </svg>
);

export const PenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M12.7874 1.21754C11.1907 -0.387958 9.62966 -0.423636 7.98833 1.21754L6.98034 2.21652C6.89114 2.30571 6.86438 2.43058 6.90006 2.54653C7.52448 4.7318 9.27285 6.48 11.4583 7.10436C11.4851 7.11328 11.5297 7.11328 11.5564 7.11328C11.6456 7.11328 11.7349 7.07761 11.7973 7.01517L12.7874 6.01619C13.5992 5.20452 14.0006 4.42853 14.0006 3.63471C14.0006 2.83196 13.5992 2.03813 12.7874 1.21754Z"
      fill="#2563EB"
    />
    <path
      d="M9.96704 7.85451C9.7262 7.73856 9.49427 7.62261 9.28019 7.48882C9.10178 7.38178 8.92338 7.26583 8.75389 7.14096C8.61117 7.05177 8.45061 6.91798 8.29004 6.78419C8.2722 6.77527 8.21868 6.73067 8.14732 6.65932C7.8708 6.43633 7.57643 6.13308 7.2999 5.80306C7.28206 5.78522 7.22854 5.73171 7.18394 5.65143C7.09474 5.55332 6.96094 5.38385 6.84497 5.19655C6.74685 5.07168 6.63089 4.89329 6.52385 4.70598C6.39005 4.483 6.27408 4.26002 6.16704 4.02811C6.05108 3.77837 5.96188 3.54647 5.88159 3.32349L1.08253 8.12209C0.770327 8.43427 0.46704 9.02294 0.404599 9.45999L0.0210312 12.118C-0.0592505 12.6799 0.0923925 13.2061 0.44028 13.554C0.734646 13.8483 1.13605 13.9999 1.58206 13.9999C1.68019 13.9999 1.77831 13.991 1.87643 13.9821L4.52573 13.6075C4.96282 13.545 5.55155 13.2507 5.86375 12.9296L10.6628 8.13101C10.4398 8.05965 10.2168 7.96154 9.96704 7.85451Z"
      fill="#2563EB"
    />
  </svg>
);
