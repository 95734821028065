import { fontOptions } from "../../utils/font";
import Icon from "../Icon";

const formStyle = [
  {
    tab: "General",
    value: "general",
    fields: [
      {
        label: "Form Name",
        key: "formName",
        type: "text",
      },
      {
        label: "Send Form to Email",
        key: "email",
        type: "text",
        placeholder: "Enter Email to send the response...",
      },
      {
        label: "Save Response",
        key: "saveResponse",
        type: "selectBox",
        placeholder: "Save Response",
      },
    ],
  },
  {
    tab: "Form Title",
    value: "formTitle",
    fields: [
      {
        label: "Title",
        key: "formTitle",
        type: "text",
      },
      {
        label: "Font Family",
        key: "fontFamily",
        type: "textOptions",
        options: fontOptions,
        width: 7,
        renderOption: (option) => {
          return (
            <span style={{ fontFamily: option.value }}>{option.text}</span>
          );
        },
      },
      {
        label: "Font Size",
        key: "textSize",
        type: "fontSize",
        width: 5,
        placeholder: "16px",
      },
      {
        label: "Text Align",
        key: "textAlign",
        type: "textAlign",
        placeholder: "16px or 1em",
      },
    ],
  },
  {
    tab: "Banner Spacing",
    value: "bannerSpacing",
    fields: [
      {
        label: "Banner Spacing",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border",
    value: "border",
    fields: [
      {
        label: "Border Radius",
        key: "borderRadius",
        type: "borderRadius",
      },
      {
        label: "Border Width",
        key: "borderWidth",
        type: "text",
        placeholder: "1px",
      },
      {
        label: "Border Style",
        key: "borderStyle",
        type: "textOptions",
        options: [
          {
            text: "Solid",
            value: "solid",
          },
          {
            text: "Dotted",
            value: "dotted",
          },
          {
            text: "Dashed",
            value: "dashed",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Text Color",
        key: "textColor",
        type: "color",
        needPreview: true,
      },
      {
        label: "Background Color",
        key: "bgColor",
        type: "color",
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Position",
    value: "position",
    fields: [
      {
        label: "Set Postion (Vertical & Horizantal)",
        key: "alignment",
        type: "alignment",
      },
    ],
  },
  {
    tab: "Background",
    value: "backgroundImage",
    fields: [
      {
        label: "URL",
        key: "backgroundImage",
        type: "text",
      },
      {
        label: "Background Image",
        key: "backgroundImage",
        type: "backgroundImage",
      },
    ],
  },
  {
    tab: "Add to Boards",
    value: "metadatamapping",
    fields: [
      {
        label: "Add response to contacts board",
        key: "metadatamapping",
        type: "metadatamapping",
        compType: "card",
        content:
          "By default, form responses are added as separate cards on the default contact board.",
        value: "mappingToContactBoard",
        infoIcon: <Icon icon="info" />,
      },
      {
        label: "Create a separate board",
        key: "metadatamapping",
        type: "metadatamapping",
        compType: "card",
        content:
          "By default, form responses are added as separate cards on a new board (Contact Us).",
        value: "mappingToSeparateBoard",
        infoIcon: <Icon icon="info" />,
      },
    ],
  },
  {
    tab: "Save As Template",
    value: "saveAsTemplate",
    needActions: false,
    fields: [
      {
        label: "Template Image",
        key: "saveAsTemplate",
        type: "saveAsTemplate",
      },
    ],
  },
];

export default formStyle;
