import React, { useState } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { IconButton, Tooltip, Box } from "@mui/material";
// import * as fIcons from "@mui/icons-material";
import MUIIcon from "../../common/StyleBuilder/fieldTypes/loadIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LinkIcon from "@mui/icons-material/Link";
import ButtonPopup from "./ButtonPopup";
import { actionButtonRedirect } from "../../service/actionTrigger";
import { WorkflowIcon } from "../../common/iconslist";
import { getTRBLBreakPoints, getBreakPointsValue } from "../../helper/theme";
import { handleLinkType, windowVar } from "../../utils/helper";
import LinkSettings from "../../common/LinkSettings";
import { useEditorTheme } from "../../hooks/useEditorTheme";
import { getTheme } from "../../theme";
import { fontFamilyMap } from "../../utils/font";

const EditorButton = (props) => {
  const { attributes, element, customProps, children } = props;
  const { readOnly, metadata } = customProps;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const [edit, setEdit] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const { theme } = useEditorTheme();
  const { buttonTheme } = getTheme(theme);

  const {
    label,
    bgColor,
    borderRadius,
    bannerSpacing,
    textColor,
    url,
    buttonLink,
    textSize,
    textAlign,
    fontFamily,
    textColorHover,
    bgColorHover,
    // buttonIcon,
    iconPosition = "start",
    borderStyle,
    borderWidth,
    borderColor,
    alignment,
    xsHidden,
    openInNewTab,
  } = element;
  const { linkType, redirectOnURLResult, actionTrigger } = buttonLink || {};
  const { onClick } = actionTrigger || { options: [] };

  const isTrigger = (linkType === "nextTrigger" || linkType === "prevTrigger");

  const refURl = isTrigger ? buttonLink?.url : url;

  const buttonIcon = element?.buttonIcon || buttonTheme?.buttonIcon;

  const BtnIcon = buttonIcon ? buttonIcon : null;

  windowVar.lastButtonProps = element;

  const handleTrigger = async () => {
    if (metadata?.buttonLink?.handler) {
      const response = isTrigger ? linkType : "click"
      metadata.buttonLink.handler(response);
    } else if (redirectOnURLResult) {
      // call api and redirect based on api result
      const apiResult = await actionButtonRedirect(
        {},
        { url: buttonLink?.url }
      );
      window.open(apiResult, "_blank").focus();
    } else {
      const refUrl = buttonLink?.url
        ? buttonLink?.url.includes("http")
          ? buttonLink?.url
          : `//${buttonLink?.url}`
        : "Link";
      window.open(refUrl, "_blank").focus();
    }
  };

  const buttonProps = handleLinkType(
    refURl,
    linkType,
    readOnly,
    openInNewTab,
    handleTrigger
  );
  console.log(openInNewTab);

  const onMenuClick = (val) => () => {
    switch (val) {
      case "edit":
        setEdit(true);
        return;
      case "delete":
        Transforms.removeNodes(editor, { at: [...path] });
        return;
      case "nav":
        setOpenNav(true);
        return;
      default:
        return;
    }
  };

  const Toolbar = () => {
    const btnProps = handleLinkType(
      refURl,
      linkType,
      true,
      true,
      handleTrigger
    );

    const hideOpenLink = linkType === "page" || !linkType;

    return !readOnly ? (
      <div
        className="element-toolbar hr"
        style={{
          width: "max-content",
          top: "-12px",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Tooltip title="Settings" arrow>
          <IconButton onClick={onMenuClick("edit")}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Nav Settings" arrow>
          <IconButton onClick={onMenuClick("nav")}>
            <LinkIcon />
          </IconButton>
        </Tooltip>

        {hideOpenLink ? null : (
          <Tooltip title="Open Link" arrow>
            <Box
              sx={{ display: "inline-flex", color: "rgba(0, 0, 0, 0.54)" }}
              {...btnProps}
              target={openInNewTab ? "_blank" : "_self"}
            >
              <OpenInNewIcon />
            </Box>
          </Tooltip>
        )}
      </div>
    ) : null;
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: [...path],
      }
    );
    setEdit(false);
  };

  const onClose = () => {
    setEdit(false);
  };

  return (
    <div
      className="editor-btn-wrapper"
      {...attributes}
      style={{ textAlign: alignment || textAlign || "left" }}
      contentEditable={false}
    >
      <Box
        component={"div"}
        className="editor-btn-wrapper-inner"
        sx={{
          display: {
            lg: "inline-block",
            xs: xsHidden ? "none" : "inline-block",
          },
          "& .element-toolbar": {
            display: "none",
          },
          "&:hover": {
            "& .element-toolbar": {
              display: "flex",
            },
          },
        }}
      >
        <span style={{ position: "relative", display: "inline-block" }}>
          <Box
            sx={{
              textDecoration: "none",
              borderBlockStyle: "solid",
              borderWidth:
                borderWidth !== undefined
                  ? borderWidth
                  : borderColor
                  ? "1px"
                  : "0px",
              borderStyle: borderStyle || "solid",
              display: "inline-flex",
              alignItems: "center",
              position: "relative",
              "& .element-toolbar": {
                display: "none",
              },
              "&:hover": {
                color: `${textColorHover || textColor}`,
                background: bgColorHover || bgColor,
                "& .element-toolbar": {
                  display: "flex",
                },
              },

              color: `${textColor} !important`,
              fontSize: `${textSize}px !important`,
              fontFamily: `${fontFamilyMap[fontFamily] || ""}  !important`,
              background: `${bgColor} !important`,
              borderColor: `${borderColor} !important`,

              borderRadius: {
                ...getBreakPointsValue(
                  borderRadius || buttonTheme?.borderRadius || {},
                  null,
                  "overrideBorderRadius",
                  true
                ),
              },
              padding: {
                ...getTRBLBreakPoints(
                  bannerSpacing || buttonTheme?.bannerSpacing || {}
                ),
              },
            }}
            {...buttonProps}
            className="button theme-element"
          >
            {BtnIcon && iconPosition === "start" && (
              <MUIIcon
                iconName={buttonIcon}
                style={{ paddingLeft: "4px", paddingRight: "4px" }}
                props={customProps}
              />
            )}
            {label || "My Button"}
            {BtnIcon && iconPosition === "end" && (
              <MUIIcon
                iconName={buttonIcon}
                style={{ paddingLeft: "4px", paddingRight: "4px" }}
                props={customProps}
              />
            )}
          </Box>
          {!readOnly && isTrigger ? (
            <IconButton className="workflow-icon-btn">
              <WorkflowIcon />
            </IconButton>
          ) : null}
          <Toolbar />
        </span>
      </Box>
      <div contentEditable={false} style={{ display: "none" }}>
        {children}
      </div>
      {edit && (
        <ButtonPopup
          element={element}
          onSave={onSave}
          onClose={onClose}
          onDelete={onMenuClick("delete")}
          customProps={customProps}
        />
      )}

      {openNav ? (
        <LinkSettings
          handleClose={() => setOpenNav(false)}
          onSave={({ linkType, navValue, openInNewTab }) => {
            onSave({
              buttonLink: { linkType, onClick },
              url: navValue,
              openInNewTab,
            });

            setOpenNav(false);
          }}
          navType={element?.buttonLink?.linkType}
          navValue={element?.url}
          openInNewTab={element?.openInNewTab}
          customProps={customProps}
        />
      ) : null}
    </div>
  );
};

export default EditorButton;
