const themeClassName = ".theme-element";

function getTextStyles(props) {
  const { fontSize, textDecoration, ...rest } = props;

  return {
    ...rest,

    '& span[data-slate-string="true"]': {
      textDecoration,
      fontSize,
    },

    // "&::after": {
    //   // for placeholder
    //   fontSize,
    // },
  };
}

const transformHeading = (props, elementType) => {
  return {
    [`& ${elementType}${themeClassName}`]: getTextStyles(props),
  };
};

const transformParagraph = (props, paraType) => {
  return {
    [`& p${themeClassName}.${paraType}`]: getTextStyles(props),
  };
};

const transformButton = (props) => {
  const {
    borderRadius,
    lockRadius,
    bannerSpacing,
    buttonIcon,
    hover,
    ...classProps
  } = props;

  return {
    [`& .button${themeClassName}`]: {
      ...classProps,

      "&:hover": hover,
    },
    buttonTheme: {
      borderRadius,
      lockRadius,
      bannerSpacing,
      buttonIcon,
    },
  };
};

const transformProps = (elementType, props) => {
  let transformedProp = {};
  let otherProps = {};

  switch (elementType) {
    case "h1":
    case "h2":
    case "h3":
    case "h4":
    case "h5":
    case "h6":
      transformedProp = transformHeading(props, elementType);
      break;
    case "para1":
    case "para2":
    case "para3":
      transformedProp = transformParagraph(props, elementType);
      break;

    case "button":
      const { buttonTheme, ...classProps } = transformButton(
        props,
        elementType
      );
      transformedProp = classProps;
      otherProps = { buttonTheme };
      break;
    default:
  }

  return { transformedProp, otherProps };
};

export const transformTheme = (selectedTheme = {}) => {
  let sxProps = {};
  let others = {};

  const { elementProps } = selectedTheme;

  if (elementProps) {
    Object.entries(elementProps).forEach(([key, value]) => {
      const { transformedProp, otherProps } = transformProps(key, value);
      sxProps = { ...sxProps, ...transformedProp };
      others = { ...others, ...otherProps };
    });
  }

  return {
    sxProps,
    ...others,
  };
};

export const defaultTheme = {
  theme: {
    cssVars: {},

    elementProps: {
      button: {
        color: "#FFFFFF",
        background: "#2563EB",
        fontFamily: "PoppinsRegular",
        fontWeight: 400,
        fontSize: "inherit",
        borderColor: "transparent",
        borderRadius: {
          topLeft: 30,
          topRight: 30,
          bottomLeft: 30,
          bottomRight: 30,
        },
        bannerSpacing: {
          left: 16,
          top: 8,
          right: 16,
          bottom: 8,
        },
      },
    },
  },
};

export function getTheme(selectedTheme) {
  return transformTheme(selectedTheme?.theme || defaultTheme.theme);
}
