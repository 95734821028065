import { MenuItem, Select } from "@mui/material";
import { fontFamilyMap } from "../utils/font";

function CustomSelect(props) {
  const { onChange, options = [], value } = props;

  return (
    <Select
      fullWidth
      value={value}
      onChange={onChange}
      className="editor-dd"
      style={{
        fontFamily: fontFamilyMap[value],
        width: "100%",
        height: "36px",
        borderRadius: "10px",
        fontSize: "14px",
      }}
    >
      {options.map((option, i) => {
        return (
          <MenuItem
            value={option.value}
            key={i}
            style={{ fontFamily: option.text }}
          >
            {option?.label || option?.text}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default CustomSelect;
