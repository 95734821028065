const Style = () => ({
  ".primaryColorBoxes": {
    ".pmBox": {
      border: "1px solid #D1D1D1",
      height: "65px",
      borderRadius: "4px",
      position: "relative",
      flexShrink: 0,
      "&:hover": {
        ".editBtn": {
          opacity: "1",
          visibility: "visible",
        },
      },
    },
  },
  ".secondaryColorBoxes": {
    gap: "10px",
    ".sdBox": {
      border: "1px solid #D1D1D1",
      height: "60px",
      borderRadius: "4px",
      width: "100%",
      flexShrink: 0,
      position: "relative",
      "&:hover": {
        ".editBtn": {
          opacity: "1",
          visibility: "visible",
        },
      },
    },
  },
  ".valyeText": {
    position: "absolute",
    right: "5px",
    bottom: "5px",
  },
  ".editBtn": {
    position: "absolute",
    right: "2px",
    top: "2px",
    padding: "2px",
    opacity: "0",
    visibility: "hidden",
  },
  ".settingsSection": {
    background: "#FFFFFF",
    border: "1px solid #E4E6E9",
    padding: "12px",
    marginTop: "10px",
    borderRadius: "7px",
  },
  ".toggleType": {
    width: "100%",
    border: "1px solid #2563EB",
    background: "#F5F6F9",
    borderRadius: "8px",
    padding: "3px",
    height: "40px",
    ".MuiToggleButton-root": {
      padding: "10px",
      width: "50%",
      border: "none",
      textTransform: "unset",
      fontSize: "14px",
      color: "#64748B",
      "&.Mui-selected": {
        borderRadius: "8px",
        background: "#2563EB",
        color: "#fff",
        fontWeight: "700",
      },
    },
  },
});

export default Style;
