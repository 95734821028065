import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";

// Style
import Style from "./style";

// icons
import { CloseIcon } from "../common/iconslist";
import { ChevronLeft } from "@mui/icons-material";
import { TickIcon } from "./icons";
import ColorTheme from "./colorTheme";
import Buttons from "./buttons";
import Fonts from "./fonts";
import { useEditorTheme } from "../hooks/useEditorTheme";
import ActiveTheme from "./ActiveTheme";
import SaveTheme from "../themeSettingsAI/saveTheme";

function getColors(themeColors) {
  const colors = Object.values(themeColors || {});
  const primaryColors = colors.slice(0, 2);
  const otherColors = colors.slice(2);
  return {
    primaryColors,
    otherColors,
  };
}

const ThemeSettings = (props) => {
  const { className, open, setOpen, services } = props;

  // State
  // const [themeDrawer, setThemeDrawer] = useState(true);
  const [themeTabType, setThemeTabType] = React.useState(0);

  const [themeMoodboard, setThemeMoodboard] = React.useState(false);

  const [themes, setThemes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savingTheme, setSavingTheme] = useState(false);

  const { theme: userSelectedTheme, updateTheme } = useEditorTheme();
  const { colors = {} } = userSelectedTheme?.theme?.cssVars || {};
  const { id, name, mood_name } = userSelectedTheme || {};

  const onClose = () => setOpen(false);

  const getThemesList = async (themeTabType) => {
    setLoading(true);
    setThemes([]);

    try {
      const themeType = themeTabType === 0 ? "default" : "";
      const result = await services("getThemes", { type: themeType });
      setThemes(result?.data || []);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    getThemesList(themeTabType);
  }, []);

  const handleThemeChange = (theme) => {
    updateTheme(theme, { action: "THEME_CHANGE" });
  };

  const onColorChange = (color, colorIndex) => {
    updateTheme(
      { [`color${colorIndex}`]: color },
      { action: "CSS_VAR_CHANGE", fieldName: "colors" }
    );
  };

  const handleTabChange = (event, newValue) => {
    setThemeTabType(newValue);
    getThemesList(newValue);
  };

  const isUpdateTheme = Number(userSelectedTheme?.type) === 2;

  const onSaveTheme = async (formData = {}) => {
    setSavingTheme(true);

    try {
      const type = isUpdateTheme ? "update" : "create";

      const result = await services("saveTheme", {
        type,
        id,
        ...formData,
        theme: userSelectedTheme?.theme,
      });

      const update = {
        ...userSelectedTheme,
        ...formData,
        type: "2",
      };

      const createdId = result?.data?.id;
      if (createdId) {
        update.id = createdId;
      }

      updateTheme(update);
      getThemesList(themeTabType);
    } catch (err) {
      console.log(err);
    }

    setSavingTheme(false);
  };

  // themeCard
  const ThemeCard = ({ theme }) => {
    const { name, mood_name = "" } = theme;
    const { colors = {} } = theme?.theme?.cssVars || {};

    const { primaryColors, otherColors } = getColors(colors);

    const isSelected = theme.id === userSelectedTheme?.id;

    return (
      <Grid
        className={`themeCardWrapper ${isSelected ? "activeWrapper" : ""}`}
        onClick={() => handleThemeChange(theme)}
      >
        {isSelected ? <TickIcon /> : null}

        <Grid
          container
          justifyContent={"space-between"}
          className="themeCard"
          gap={2}
        >
          <Grid>
            <Typography
              variant="body1"
              className="title"
              style={{
                whiteSpace: "nowrap",
                maxWidth: "100px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {name}
            </Typography>
            <Typography
              variant="body2"
              color={"textSecondary"}
              className="fs-12"
            >
              {mood_name}
            </Typography>
          </Grid>
          <Grid className="flexAlign" sx={{ gap: "5px" }}>
            {primaryColors?.map((color, i) => {
              return (
                <Grid
                  className="primaryCard"
                  style={{ backgroundColor: color }}
                  key={i}
                ></Grid>
              );
            })}
            <Grid className="otherColors" sx={{ gap: "5px" }}>
              {otherColors.map((color, i) => {
                return (
                  <Grid
                    className="smallCard"
                    key={i}
                    style={{ backgroundColor: color }}
                  ></Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className={className}>
      <Drawer
        className={className}
        open={open}
        onClose={onClose}
        anchor="right"
      >
        <Grid className="settingsContainer">
          {/* Header */}
          <Grid
            container
            justifyContent={"space-between"}
            wrap="nowrap"
            className="header"
            sx={{ px: 2 }}
          >
            <Grid>
              <Typography variant="body1" className="title">
                Theme/Brand Setting
              </Typography>
              <Typography
                variant="body1"
                color={"textSecondary"}
                className="fs-12"
              >
                Change the entire style of your website with a single click.{" "}
              </Typography>
            </Grid>
            <IconButton className="closeBtn" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          {/* // Header */}

          {/* Active theme */}
          <Grid className="activeTheme">
            {/* theme moodboard */}

            {!themeMoodboard ? (
              <ActiveTheme onExplore={() => setThemeMoodboard(true)} />
            ) : (
              <Grid className="sectionDetail">
                {/* theme moodboard */}
                <Typography variant="body1" className="sectionTitle flexAlign">
                  <IconButton onClick={() => setThemeMoodboard(false)}>
                    <ChevronLeft />
                  </IconButton>
                  Theme Moodboard
                </Typography>
                <Grid className="activeWrapper">
                  <TickIcon />
                  <ActiveTheme explore={false} />
                </Grid>
                <Tabs
                  value={themeTabType}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                >
                  <Tab
                    label={
                      <Typography
                        variant="body1"
                        className="fs-14 fw-500 transformNone"
                      >
                        Key themes
                      </Typography>
                    }
                  />
                  <Tab
                    label={
                      <Typography
                        variant="body1"
                        className="fs-14 fw-500 transformNone"
                      >
                        Saved themes
                      </Typography>
                    }
                  />
                </Tabs>

                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 0,
                      padding: 0,
                      height: "50px",
                      overflow: "hidden",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : null}

                {themes.map((theme, i) => {
                  return <ThemeCard key={i} theme={theme} />;
                })}
              </Grid>
            )}
          </Grid>
          {/* // Active theme */}

          {id ? (
            <>
              <Grid sx={{ px: 1 }}>
                {/* Color Theme */}
                <ColorTheme
                  colors={getColors(colors)}
                  onColorChange={onColorChange}
                  openDefault={open === "editThemeColor"}
                />
                {/* Color Theme */}
              </Grid>

              <Grid sx={{ px: 1 }}>
                {/* fonts */}
                <Fonts />
                {/* fonts */}
              </Grid>

              <Grid sx={{ px: 1 }}>
                {/* buttons */}
                <Buttons />
                {/* buttons */}
              </Grid>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <SaveTheme
                  saveBtnLabel={isUpdateTheme ? "Update theme" : "Save Theme"}
                  loading={savingTheme}
                  onSave={onSaveTheme}
                  defaultFormData={{ name, mood_name }}
                />
              </div>
            </>
          ) : null}
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default styled(ThemeSettings)(Style);
