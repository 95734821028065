import React from "react";
import { Box, Button, Card, CardMedia, Grid } from "@mui/material";

const Select = (props) => {
    const { classes, data, onSelectTemplate } = props;
    return (
        <Box
            className="template-card-action"
            component={"div"}
            sx={classes.templateCardBtnGrp}
            style={{ padding: 0, background: "transparent"}}
        >
            <Button className="blueBtn" onClick={onSelectTemplate(data)}>
                Select
            </Button>
        </Box>
    );
};

const ButtonTemplateCard = (props) => {

    const { classes, m, onSelectTemplate } = props;

    return (
        <Grid item xs={3} key={`template_${m.id}`}>
            <Card sx={classes.paperOverrides}>
                <Box sx={classes.buttonCardMediaWrpr} onClick = {onSelectTemplate(m)} >
                    {/* <div className="img-loader-wrapper" /> */}
                    <div className="img-wrapper">
                        <CardMedia
                            className={`template-card-media`}
                            component="div"
                            image={m?.thumbnail}
                            alt={m?.title}
                            sx={classes.buttonCardMedia}
                        />
                    </div>
                    {/* <Select
                        classes={classes}
                        onSelectTemplate={onSelectTemplate}
                        data={m}
                    /> */}
                </Box>
            </Card>
        </Grid>
    )
}

export default ButtonTemplateCard