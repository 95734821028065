export const ThemePaintIcon = () => (
  <svg
    width="32"
    height="36"
    viewBox="0 0 36 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4325 30.1183C12.139 31.1876 14.0973 31.7882 16.1099 31.8595C16.8893 31.8902 17.6699 31.8481 18.4415 31.7337L17.8907 33.3571C17.2776 33.414 16.6614 33.4296 16.0462 33.4036C13.7653 33.3205 11.5466 32.6376 9.61376 31.4238C7.53754 30.0913 5.95828 28.1117 5.12029 25.7914C4.23027 23.3448 4.06149 20.6941 4.634 18.1544C5.32901 15.0434 7.1361 12.2933 9.71575 10.4207C12.3096 8.51989 15.5017 7.61749 18.7068 7.87886C19.755 7.92879 20.7757 8.23069 21.6825 8.75897C22.0763 8.9939 22.4171 9.30786 22.6835 9.68107C22.9499 10.0543 23.1362 10.4786 23.2304 10.9274C23.2405 10.9823 23.245 11.0381 23.2441 11.0939C23.2158 12.8879 21.9918 13.4622 20.8255 14.0113C20.1397 14.3232 19.5069 14.6321 19.5387 15.2172C19.5497 15.4668 19.7385 15.7377 20.0664 16.0015C20.5942 16.3982 21.197 16.6835 21.8383 16.8402C22.4605 17.0128 23.1214 17.1542 23.7449 17.3003C24.1251 17.3841 24.4862 17.462 24.8522 17.553L23.7035 18.879L23.4025 18.8123C22.7815 18.6728 22.1281 18.5267 21.4188 18.3306C20.5732 18.1157 19.7797 17.7322 19.0859 17.2032C18.4162 16.6584 18.0267 15.9997 17.9896 15.2953C17.9042 13.6668 19.0075 13.1488 20.1607 12.6046C20.8766 12.2684 21.6273 11.9155 21.6909 11.1512C21.5627 10.6932 21.2613 10.3032 20.8504 10.0638C20.1561 9.66831 19.3778 9.4434 18.5796 9.40758C15.747 9.18118 12.9274 9.98108 10.6357 11.6612C8.36068 13.3186 6.76827 15.7488 6.15706 18.4964C5.64599 20.7451 5.79061 23.0931 6.57376 25.2621C7.29184 27.2606 8.64787 28.9672 10.4325 30.1183Z"
      fill="#64748B"
      stroke="#64748B"
      strokeWidth="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.857 14.7924C11.8336 15.2307 11.9404 15.666 12.1639 16.0437C12.3873 16.4214 12.7175 16.7245 13.1128 16.915C13.5082 17.1055 13.951 17.1748 14.3857 17.1142C14.8203 17.0536 15.2273 16.8658 15.5555 16.5744C15.8836 16.283 16.1183 15.9011 16.2299 15.4767C16.3415 15.0523 16.3251 14.6044 16.1828 14.1893C16.0404 13.7741 15.7784 13.4104 15.4299 13.1438C15.0813 12.8772 14.6616 12.7197 14.2237 12.691C13.93 12.6718 13.6353 12.7111 13.3568 12.8066C13.0783 12.9021 12.8216 13.0519 12.6014 13.2474C12.3813 13.4429 12.2021 13.6801 12.0743 13.9453C11.9466 14.2105 11.8727 14.4985 11.857 14.7924Z"
      fill="#45B51D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.90225 20.2707C7.87487 20.7146 7.97972 21.1567 8.20357 21.5411C8.42741 21.9255 8.76018 22.2348 9.15982 22.4301C9.55946 22.6254 10.008 22.6978 10.4488 22.6382C10.8896 22.5785 11.3028 22.3896 11.6362 22.0951C11.9696 21.8007 12.2082 21.4141 12.3219 20.984C12.4356 20.554 12.4192 20.0999 12.2749 19.6792C12.1306 19.2585 11.8647 18.89 11.511 18.6203C11.1573 18.3507 10.7315 18.1919 10.2876 18.1642C9.69229 18.1282 9.10708 18.3301 8.66061 18.7255C8.21415 19.1209 7.94298 19.6775 7.90674 20.2727L7.90225 20.2707Z"
      fill="#E89431"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1791 24.7302C18.1451 25.2948 18.2983 25.8549 18.6148 26.3236C18.9314 26.7923 19.3937 27.1435 19.9302 27.3228L21.5794 22.4539C21.3605 22.3786 21.1322 22.3338 20.9011 22.3209C20.5638 22.2992 20.2255 22.3442 19.9057 22.4534C19.5858 22.5627 19.2907 22.734 19.0372 22.9575C18.7837 23.1811 18.5768 23.4525 18.4284 23.7561C18.28 24.0598 18.193 24.3898 18.1724 24.7271L18.1791 24.7302Z"
      fill="#E831D5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.7335 9.18621C31.3102 11.8459 26.9461 11.8944 26.9128 15.3245C26.9126 15.8075 27.0124 16.2852 27.2061 16.7277C27.3998 17.1701 27.6831 17.5676 28.0381 17.895C28.4548 18.2729 28.9983 18.4802 29.5607 18.476C31.813 18.4825 33.0805 16.295 33.3333 14.2829C33.5585 12.4266 33.106 10.4893 32.7335 9.18621ZM28.6753 24.4217C27.0476 24.0905 25.5122 23.4072 24.1766 22.4198L19.9589 34.8736C19.8518 35.1342 19.7782 35.4074 19.74 35.6866C19.7196 35.902 19.7541 36.119 19.8402 36.3175C19.9263 36.516 20.0612 36.6895 20.2324 36.8218C20.4035 36.9542 20.6053 37.0411 20.8191 37.0745C21.0329 37.1079 21.2516 37.0867 21.455 37.0129C21.7724 36.8627 22.0272 36.6059 22.1748 36.2872L28.6785 24.4205L28.6753 24.4217ZM29.2912 19.8582C28.6241 19.8255 27.5539 19.4973 26.9954 18.8102L24.5583 21.6431C25.9722 22.7123 27.476 23.4776 29.1409 23.7349C29.0576 21.977 29.0679 21.7996 29.277 19.8654L29.2912 19.8582Z"
      fill="#64748B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3398 27.0233C11.3122 27.4672 11.4169 27.9093 11.6405 28.2937C11.8642 28.6781 12.1967 28.9876 12.5962 29.1831C12.9957 29.3785 13.4441 29.4512 13.8849 29.3919C14.3257 29.3325 14.7389 29.1439 15.0725 28.8497C15.4061 28.5556 15.6449 28.1691 15.7589 27.7393C15.873 27.3094 15.857 26.8554 15.713 26.4346C15.5691 26.0138 15.3036 25.6451 14.9502 25.3751C14.5967 25.1052 14.1712 24.946 13.7274 24.9178C13.1321 24.8819 12.5469 25.0838 12.1004 25.4792C11.6539 25.8746 11.3828 26.4311 11.3465 27.0264L11.3398 27.0233Z"
      fill="#3F31E8"
    />
  </svg>
);

export const ThemeAIIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_2642_42364)">
      <rect width="32" height="32" rx="16" fill="white" />
    </g>
    <path
      d="M6.46812 17.5346C6.64285 16.2169 7.27499 15.7965 7.56923 15.751C7.46913 15.8966 7.25437 16.2442 7.19613 16.4699C7.12333 16.752 7.07783 17.5346 7.56923 18.3081C8.06063 19.0816 8.94333 19.5275 9.78964 19.7914C10.4667 20.0025 11.1091 19.9643 11.3457 19.9188C11.5641 19.9279 11.375 20.1312 10.5813 20.4193C9.70773 20.7378 8.75223 20.8197 7.96053 20.3738C7.16883 19.9279 6.24972 19.1817 6.46812 17.5346Z"
      fill="url(#paint0_linear_2642_42364)"
    />
    <path
      d="M6.46812 17.5346C6.64285 16.2169 7.27499 15.7965 7.56923 15.751C7.46913 15.8966 7.25437 16.2442 7.19613 16.4699C7.12333 16.752 7.07783 17.5346 7.56923 18.3081C7.78693 18.6508 8.08142 18.9291 8.41553 19.1559C8.56113 19.2363 8.61573 19.2909 8.61573 19.2909C8.38823 19.3364 8.03333 19.391 7.56923 19.2909C7.10513 19.1908 6.65923 18.9269 6.4809 18.5265C6.42764 18.2378 6.41841 17.9095 6.46812 17.5346Z"
      fill="url(#paint1_linear_2642_42364)"
    />
    <path
      d="M7.52201 19.3642C7.08521 18.8 6.6543 17.8559 7.20031 16.4727C7.08201 16.8731 7.25037 17.6307 7.40507 18.0129C7.55977 18.3951 8.15492 19.1044 9.14646 19.5735C9.99276 19.9739 11.0043 19.9739 11.4805 19.9375C11.2348 20.0528 10.5396 20.2852 9.72422 20.2924C8.70502 20.3015 8.02251 19.8647 7.52201 19.3642Z"
      fill="url(#paint2_linear_2642_42364)"
    />
    <path
      d="M19.7027 22.4996C21.4315 22.182 22.7057 21.3502 23.7168 19.2824C23.458 19.5092 22.8954 20.0511 22.4673 20.3206C21.9321 20.6575 20.9398 21.0412 20.5712 21.4316C19.9052 22.0468 18.8501 22.3866 18.4018 22.434C17.9535 22.4813 18.1881 22.7278 19.7027 22.4996Z"
      fill="url(#paint3_linear_2642_42364)"
    />
    <path
      d="M7.81229 14.4839C6.53617 15.1533 6.20086 16.7097 6.28989 17.7157C6.37892 18.7217 6.63989 18.8837 6.58803 18.7946C6.41887 17.6105 6.63711 16.2823 7.58972 15.9351C8.54233 15.5879 9.46823 16.3624 10.2784 17.1459C11.0886 17.9294 12.6555 19.3182 13.2609 19.9236C13.8663 20.529 16.1869 22.4805 17.9497 22.5785C19.5878 22.6695 20.487 21.8039 21.1667 20.9564C21.5752 20.4469 21.8967 19.2025 21.7365 19.3538C21.5762 19.5052 21.14 19.9147 20.3209 20.1373C19.5018 20.3599 18.4335 20.253 17.5076 19.9681C16.5817 19.6832 15.3798 18.8553 14.623 18.1608C13.8663 17.4664 12.0323 15.6235 11.1865 14.9914C10.3407 14.3593 9.04059 13.8396 7.81229 14.4839Z"
      fill="url(#paint4_linear_2642_42364)"
    />
    <path
      d="M11.1955 14.9745C10.3497 14.3424 9.03209 13.7904 7.82129 14.467C6.9458 14.9745 6.71432 15.5639 6.84787 15.4125C6.98141 15.2612 7.93402 13.9792 9.50094 14.8249C11.0678 15.6707 12.6882 17.8697 13.9791 19.0805C15.27 20.2913 16.1574 21.0036 17.5166 21.4042C18.8757 21.8048 20.6059 21.5431 21.1757 20.9395C21.5842 20.43 21.9057 19.1856 21.7455 19.3369C21.5852 19.4883 21.149 19.8978 20.3299 20.1204C19.5108 20.343 18.4425 20.2361 17.5166 19.9512C16.5907 19.6663 15.3888 18.8384 14.632 18.1439C13.8753 17.4495 12.0413 15.6066 11.1955 14.9745Z"
      fill="url(#paint5_linear_2642_42364)"
    />
    <path
      d="M21.0103 21.0923C20.8857 21.2258 21.9376 20.8171 22.9525 20.0247C23.2614 19.7836 23.4052 19.6945 23.61 19.463C23.8014 19.2467 24.19 17.8346 24.19 17.1669C24.1544 15.8136 23.798 14.9688 23.7181 14.7979C23.61 14.5663 23.5743 14.4685 23.61 15.154C23.6456 15.8396 23.5565 16.7655 23.1737 17.5667C22.6841 18.368 22.3814 18.5817 21.8561 19.1336C21.7403 19.2583 21.7849 19.4898 21.6869 19.7836C21.5801 20.1308 21.1349 20.9587 21.0103 21.0923Z"
      fill="url(#paint6_linear_2642_42364)"
    />
    <path
      d="M8.62628 19.2839C8.48382 19.3266 8.84884 19.5689 9.88158 19.8894C10.3 19.9784 11.1903 20.2276 11.5909 19.9428C12.1518 19.6789 13.4338 18.4649 13.9769 17.913C14.4339 17.4767 15.5545 16.4867 16.3807 16.0166C17.4135 15.429 18.9537 14.7435 20.1288 15.1263C21.304 15.5092 21.767 16.9871 21.7848 17.6103C21.8026 18.2335 21.7848 19.0347 21.7848 19.2395C21.7818 19.2929 21.84 19.325 22.0964 19.0258C22.4169 18.6519 22.7997 18.4649 23.2894 17.3254C23.4763 16.8565 23.7874 15.5537 23.6633 14.8326C23.556 14.2093 23.2979 13.8799 22.8086 13.328C22.3194 12.776 21.2328 11.9836 19.9241 11.9747C18.6153 11.9658 17.5915 12.5178 17.0306 12.7849C16.0869 13.2211 14.5467 14.3874 13.7187 15.2154C12.8819 15.981 12.0361 16.8357 11.5108 17.361C11.0906 17.7812 10.4632 18.3908 10.2021 18.643C9.67681 19.0525 8.83104 19.2225 8.62628 19.2839Z"
      fill="url(#paint7_linear_2642_42364)"
    />
    <path
      d="M11.601 19.948C11.2378 20.1189 10.3101 19.9836 9.89163 19.8946C10.0535 19.8351 10.5856 19.5365 11.4189 18.8172C12.4606 17.9181 13.9563 16.529 15.3006 15.6834C16.912 14.6952 18.4513 14.2412 20.1389 15.1315C18.9637 14.7487 17.4235 15.4342 16.3908 16.0218C15.5646 16.4919 14.444 17.4819 13.987 17.9181C13.4439 18.4701 12.1619 19.684 11.601 19.948Z"
      fill="url(#paint8_linear_2642_42364)"
    />
    <path
      d="M22.067 13.3464C21.3334 12.6556 19.9096 12.6312 19.2893 12.7054L23.0998 15.6256C23.0998 15.6256 22.984 14.21 22.067 13.3464Z"
      fill="url(#paint9_linear_2642_42364)"
    />
    <path
      d="M15.2273 25.6144C14.9918 25.6144 14.801 25.4235 14.801 25.188C14.801 24.9526 14.9918 24.7617 15.2273 24.7617C15.4627 24.7617 15.6536 24.9526 15.6536 25.188C15.6536 25.4235 15.4627 25.6144 15.2273 25.6144Z"
      fill="#8360FE"
    />
    <path
      d="M24.6082 11.1212C24.3728 11.1212 24.1819 10.9303 24.1819 10.6949C24.1819 10.4594 24.3728 10.2686 24.6082 10.2686C24.8437 10.2686 25.0345 10.4594 25.0345 10.6949C25.0345 10.9303 24.8437 11.1212 24.6082 11.1212Z"
      fill="#8360FE"
    />
    <path
      d="M27.1665 19.2218C26.9311 19.2218 26.7402 19.0309 26.7402 18.7955C26.7402 18.56 26.9311 18.3691 27.1665 18.3691C27.402 18.3691 27.5929 18.56 27.5929 18.7955C27.5929 19.0309 27.402 19.2218 27.1665 19.2218Z"
      fill="#8360FE"
    />
    <path
      d="M5.64086 10.4327C5.69392 10.2138 6.00514 10.2138 6.05821 10.4327L6.20421 11.0348C6.22315 11.1129 6.28415 11.1739 6.36228 11.1929L6.96439 11.3389C7.18322 11.3919 7.18322 11.7031 6.96439 11.7562L6.36228 11.9022C6.28415 11.9212 6.22315 11.9822 6.20421 12.0603L6.05821 12.6624C6.00514 12.8812 5.69392 12.8812 5.64086 12.6624L5.49486 12.0603C5.47591 11.9822 5.41491 11.9212 5.33679 11.9022L4.73468 11.7562C4.51585 11.7031 4.51585 11.3919 4.73468 11.3389L5.33679 11.1929C5.41491 11.1739 5.47591 11.1129 5.49486 11.0348L5.64086 10.4327Z"
      fill="#8360FE"
      fillOpacity="0.73"
    />
    <path
      d="M26.1036 22.3682C26.1567 22.1494 26.4679 22.1494 26.521 22.3682L26.667 22.9703C26.6859 23.0485 26.7469 23.1095 26.825 23.1284L27.4272 23.2744C27.646 23.3275 27.646 23.6387 27.4272 23.6917L26.825 23.8378C26.7469 23.8567 26.6859 23.9177 26.667 23.9958L26.521 24.5979C26.4679 24.8168 26.1567 24.8168 26.1036 24.5979L25.9576 23.9958C25.9387 23.9177 25.8777 23.8567 25.7996 23.8378L25.1974 23.6917C24.9786 23.6387 24.9786 23.3275 25.1974 23.2744L25.7996 23.1284C25.8777 23.1095 25.9387 23.0485 25.9576 22.9703L26.1036 22.3682Z"
      fill="#8360FE"
      fillOpacity="0.73"
    />
    <path
      d="M12.9572 6.96683C12.9925 6.82095 13.2 6.82095 13.2354 6.96683L13.3327 7.36824C13.3454 7.42033 13.386 7.46099 13.4381 7.47362L13.8395 7.57096C13.9854 7.60633 13.9854 7.81381 13.8395 7.84919L13.4381 7.94652C13.386 7.95915 13.3454 7.99982 13.3327 8.0519L13.2354 8.45331C13.2 8.5992 12.9925 8.5992 12.9572 8.45331L12.8598 8.0519C12.8472 7.99982 12.8065 7.95915 12.7544 7.94652L12.353 7.84919C12.2072 7.81381 12.2072 7.60633 12.353 7.57096L12.7544 7.47362C12.8065 7.46099 12.8472 7.42033 12.8598 7.36824L12.9572 6.96683Z"
      fill="#8360FE"
    />
    <path
      d="M8.33812 23.1113C8.35581 23.0384 8.45955 23.0384 8.47724 23.1113L8.5259 23.3121C8.53222 23.3381 8.55255 23.3584 8.57859 23.3647L8.7793 23.4134C8.85224 23.4311 8.85224 23.5348 8.7793 23.5525L8.57859 23.6012C8.55255 23.6075 8.53222 23.6278 8.5259 23.6539L8.47724 23.8546C8.45955 23.9275 8.35581 23.9275 8.33812 23.8546L8.28945 23.6539C8.28314 23.6278 8.26281 23.6075 8.23676 23.6012L8.03606 23.5525C7.96312 23.5348 7.96312 23.4311 8.03606 23.4134L8.23676 23.3647C8.26281 23.3584 8.28314 23.3381 8.28945 23.3121L8.33812 23.1113Z"
      fill="#2563EB"
    />
    <path
      d="M12.6004 12.0274C12.6181 11.9544 12.7218 11.9544 12.7395 12.0274L12.7882 12.2281C12.7945 12.2541 12.8148 12.2744 12.8409 12.2808L13.0416 12.3294C13.1145 12.3471 13.1145 12.4509 13.0416 12.4685L12.8409 12.5172C12.8148 12.5235 12.7945 12.5439 12.7882 12.5699L12.7395 12.7706C12.7218 12.8435 12.6181 12.8435 12.6004 12.7706L12.5517 12.5699C12.5454 12.5439 12.5251 12.5235 12.499 12.5172L12.2983 12.4685C12.2254 12.4509 12.2254 12.3471 12.2983 12.3294L12.499 12.2808C12.5251 12.2744 12.5454 12.2541 12.5517 12.2281L12.6004 12.0274Z"
      fill="#8360FE"
    />
    <path
      d="M19.7767 24.0176C19.8121 23.8717 20.0196 23.8717 20.055 24.0176L20.1523 24.419C20.1649 24.4711 20.2056 24.5118 20.2577 24.5244L20.6591 24.6217C20.805 24.6571 20.805 24.8646 20.6591 24.9L20.2577 24.9973C20.2056 25.0099 20.1649 25.0506 20.1523 25.1027L20.055 25.5041C20.0196 25.65 19.8121 25.65 19.7767 25.5041L19.6794 25.1027C19.6668 25.0506 19.6261 25.0099 19.574 24.9973L19.1726 24.9C19.0267 24.8646 19.0267 24.6571 19.1726 24.6217L19.574 24.5244C19.6261 24.5118 19.6668 24.4711 19.6794 24.419L19.7767 24.0176Z"
      fill="#8360FE"
    />
    <defs>
      <filter
        id="filter0_i_2642_42364"
        x="0"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.513726 0 0 0 0 0.376471 0 0 0 0 0.992157 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2642_42364"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2642_42364"
        x1="10.6852"
        y1="18.4992"
        x2="8.93073"
        y2="20.6834"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8D2EB0" />
        <stop offset="0.932292" stopColor="#6E11B8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2642_42364"
        x1="6.21705"
        y1="15.6054"
        x2="7.79136"
        y2="19.7004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2563EB" />
        <stop offset="1" stopColor="#BA6CD9" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2642_42364"
        x1="5.97055"
        y1="18.254"
        x2="9.71066"
        y2="19.4734"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D9D9" stopOpacity="0.59" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2642_42364"
        x1="21.5312"
        y1="19.9851"
        x2="19.899"
        y2="23.3303"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#615EFF" />
        <stop offset="1" stopColor="#FF5EB0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2642_42364"
        x1="24.1491"
        y1="17.2527"
        x2="6.28099"
        y2="17.6712"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8360FD" />
        <stop offset="1" stopColor="#A037D9" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2642_42364"
        x1="19.5707"
        y1="18.8936"
        x2="8.66458"
        y2="18.1012"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CFB9FE" />
        <stop offset="1" stopColor="#0029FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2642_42364"
        x1="24.3503"
        y1="14.7542"
        x2="20.5665"
        y2="22.3394"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92FF" />
        <stop offset="1" stopColor="#0001C5" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2642_42364"
        x1="25.2124"
        y1="11.1556"
        x2="17.6805"
        y2="23.3615"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2563EB" />
        <stop offset="0.308321" stopColor="#4C60FF" />
        <stop offset="0.802083" stopColor="#925FFF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2642_42364"
        x1="18.0961"
        y1="13.2974"
        x2="14.072"
        y2="19.8856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2642_42364"
        x1="22.6546"
        y1="12.0822"
        x2="20.5714"
        y2="14.5483"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.295908" stopColor="#D9D9D9" />
        <stop offset="0.667869" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
