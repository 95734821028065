import React from "react";
import { Box } from "@mui/material";
import { getBreakPointsValue, getTRBLBreakPoints } from "../../helper/theme";

const AccordionSummary = (props) => {
  const { attributes, children, element } = props;
  const { textColor, bgColor, borderColor, borderRadius, bannerSpacing } =
    element;

  return (
    <Box
      className={`accordion-summary-container`}
      {...attributes}
      sx={{
        width: "100%",
        position: "relative",
        backgroundColor: bgColor,
        border: `1px solid ${borderColor}`,
        color: textColor,
        borderRadius: {
          ...getBreakPointsValue(
            borderRadius || {},
            null,
            "overrideBorderRadius",
            true
          ),
        },
        padding: {
          ...getTRBLBreakPoints(bannerSpacing),
        },
      }}
      component={"div"}
    >
      {children}
    </Box>
  );
};

export default AccordionSummary;
