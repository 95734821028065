import React, { useCallback, useMemo, useRef, useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Editable, Slate, ReactEditor } from 'slate-react'
import { createEditor, Transforms, Editor } from 'slate'
import { useDebounce } from "use-debounce";

import withCommon from "./hooks/withCommon";
import { getBlock, getMarked } from "./utils/chatEditor/SlateUtilityFunctions";
import MiniTextFormat from "./Toolbar/PopupTool/MiniTextFormat";
import { commands, mentionsEvent } from "./utils/events";
import { insertEmoji } from "./utils/emoji";
import { draftToSlate } from "./utils/draftToSlate";
import MentionsPopup from "./common/MentionsPopup";
import { serializeToText } from "./utils/serializeToText";
import useMentions from "./hooks/useMentions";
import Shorthands from "./common/Shorthands";
import usePopupStyle from "./Toolbar/PopupTool/PopupToolStyle";
import { EditorProvider } from "./hooks/useMouseMove";


const ChatEditor = forwardRef((props, ref) => {
    const { id, theme, content, readOnly, otherProps, needLayout=false, toolBar=true, onSave, onsubmit, onBlur = ()=>{} } = props;
    const classes = usePopupStyle(theme);
    const convertedContent = draftToSlate({ data: content && content?.length > 0 ? content : [{ type: 'paragraph', children: [{ text: '' }] }] });
    const [isInteracted, setIsInteracted] = useState(false);
    const [value, setValue] = useState(convertedContent);
    const [loadedValue] = useState(value);
    const [deboundedValue] = useDebounce(value, 500);
    const [isExternalUpdate, setIsExternalUpdate] = useState(false);
    const editor = useMemo(() => {
        return withCommon(createEditor(), { needLayout });
      }, []);
    const isReadOnly = readOnly === "readonly";

    useImperativeHandle(ref, () => ({
        emojiClick: (emoji) => {
            if (editor) {
                ReactEditor.focus(editor);
                insertEmoji(editor, emoji?.native, editor.selection);
                ReactEditor.focus(editor);
            }
        },
        

        submitChat: () => {
            const { value: strVal, ...restVal } = getOnSaveData(value);
            onsubmit(false, { strVal, restVal });
        },

        // Focus enable
        enableFocus: () => {
            if (editor) {
                const position = {
                    anchor: { path: [0], offset: 0 },
                    focus: { path: [0], offset: 0 },
                };
                Transforms.select(editor, position);
                ReactEditor.focus(editor);
            }
        },
    
        clearAll: (content = null, clear=true) => {
            if (!editor) return;
            try {
                if(clear) {
                    while (editor.children.length > 0) {
                        Transforms.removeNodes(editor, { at: [0] });
                    }
                }
                const newValue = draftToSlate({ data: content })
                setValue(newValue);
                setTimeout(() => {
                    if(editor.children.length === 0) {
                        Transforms.insertNodes(editor, newValue);
                    }
                    Transforms.select(editor, Editor.end(editor, []))
                    ReactEditor.focus(editor);
                  }, 300);
                
            } catch {
                console.log("error:")
            }
            
        }
      }));

    // useEffect(() => {
    //     setIsExternalUpdate(true);
    //     setValue(draftToSlate({ data: content }));
    // }, [content]);


    useEffect(() => {
        if (JSON.stringify(loadedValue) !== JSON.stringify(deboundedValue) &&
          isInteracted &&
          onSave
        ) {
          const { value: strVal, ...restVal } = getOnSaveData(deboundedValue);
          onSave(strVal, restVal);
        }
      }, [deboundedValue]);

      const getOnSaveData = (val) => {
        const text = serializeToText(val);      
        const title = val?.find((f) => f.type === "title");
        return {
          value: JSON.stringify(val),
          text: text,
          title: serializeToText(title?.children) || "Untitled",
        };
      };

    
    const {
        CHARACTERS = [],
        hideTools,
        // needLayout = true,
    } = otherProps || {};

    const mentionsRef = useRef();

    const customProps = {
        ...(otherProps || {}),
        readOnly: isReadOnly,
        editorPlaceholder: "Write Something",
        page_id: 1,
    };

    const [mentions, setMentions] = useMentions({
        editor,
        selection: editor?.selection,
    });

    const { search, target, index } = mentions;
    let { type } = mentions;
    if(type && type === "elements" && hideTools.indexOf("slash") > -1) {
        type = null
    }
    const chars = type ? Shorthands[type]({ ...mentions, CHARACTERS, hideTools: hideTools }) : [];

    const Leaf = ({ attributes, children, leaf }) => {
        children = getMarked(leaf, children);
        return <span {...attributes}>{children}</span>;
    };

    const handleEditorChange = (newValue) => {
        setValue(newValue);
        if (!isInteracted) {
            setIsInteracted(true);
        }
    };
    

    const Element = (props) => {
        return getBlock(props);
    };
    const renderElement = useCallback((props) => {
        return <Element {...props} customProps={customProps} />;
    }, []);
    const renderLeaf = useCallback((props) => {
        return <Leaf {...props} customProps={customProps} />;
    }, []);

    const onKeyDown = useCallback(
        (event) => {
            const isMetaKey = event.metaKey && event.keyCode >= 65 && event.keyCode <= 90;
            const isCtrlKey = event.ctrlKey || isMetaKey;
            if (target && chars.length > 0 && !isCtrlKey) {
                mentionsEvent({
                    event,
                    mentions,
                    setMentions,
                    chars,
                    target,
                    editor,
                    type,
                    mentionsRef,
                });
            } else if (isCtrlKey) {
                commands({
                    event,
                    editor,
                });
            } else if (event.key === "Enter") {
                const isEmpty = value.length === 1 && value[0].type === 'paragraph' && value[0].children.length === 1 && value[0].children[0].text === '';
                if (isEmpty) {
                    event.preventDefault();
                    return;
                }
                if (!event.shiftKey) {
                    const { value: strVal, ...restVal } = getOnSaveData(value);
                    onsubmit(false, { strVal, restVal });
                    event.preventDefault();
                }
            }
        },
        [chars, editor, target, mentions, setMentions, search, type, mentionsRef]
    );

    const handleClose = () => {

      };

    const handleBlur = () => {
        const { value: strVal, ...restVal } = getOnSaveData(value);
        onBlur({strVal, restVal})
    };

    return (
        <EditorProvider theme={theme} editor={editor}>
            <Slate
                key={id}
                editor={editor}
                initialValue={value}
                onChange={handleEditorChange}
            >
                {toolBar && <MiniTextFormat classes={classes} editor={editor} closeMainPopup={handleClose} /> }
                <Editable
                    className={"chatEditorRoot"}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder="Start typing ..."
                    spellCheck
                    autoFocus
                    onBlur={handleBlur}
                    onKeyDown={onKeyDown}
                />
                {!readOnly ? (
                    <MentionsPopup
                        ref={mentionsRef}
                        mentions={mentions}
                        setMentions={setMentions}
                        editor={editor}
                        target={target}
                        index={index}
                        chars={chars}
                        type={type}
                        theme={theme}
                    />
                ) : null}
            </Slate>
        </EditorProvider>
    )
})

ChatEditor.displayName = "ChatEditor";

export default ChatEditor;