import React, { useState } from "react";
import { Grid, Button, Popover } from "@mui/material";
import { colors } from "../Elements/Color Picker/defaultColors";
import CustomColorPicker from "./CustomColorPicker";

const ColorPickerButton = (props) => {
  const {
    value,
    onSave,
    classes = {},
    recentColors = [],
    children,
    handleClose: closeDrawer,
    hideThemeColors,
    disableEditTheme,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState(value);
  const open = Boolean(anchorEl);

  const handleColorPicker = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSave = () => {
    onSave(color);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color) => {
    setColor(color);
  };

  return (
    <>
      {children ? (
        <div onClick={handleColorPicker} style={{ cursor: "pointer" }}>
          {children}
        </div>
      ) : (
        <Button
          style={{
            background: value,
            height: "22px",
            minWidth: "22px",
            borderRadius: "26px",
            border: "2px solid #E7E7E7",
          }}
          onClick={handleColorPicker}
        />
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handleClose}
        sx={classes.colorPickerPopup}
      >
        <Grid container padding={0} className="ed-cp-wrpr">
          <Grid item xs={12}>
            <div>
              <CustomColorPicker
                gradient
                color={color}
                onChange={handleColorChange}
                recentColors={recentColors}
                closeDrawer={closeDrawer}
                hideThemeColors={hideThemeColors}
                disableEditTheme={disableEditTheme}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "8px",
                background: "white",
                position: "sticky",
                bottom: 0,
                boxShadow: "0 -1px 5px rgba(0,0,0,0.1)",
              }}
            >
              <Button onClick={handleClose} className="secondaryBtn">
                Cancel
              </Button>
              <Button onClick={handleSave} className="primaryBtn">
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

ColorPickerButton.defaultProps = {
  defaultColors: [...colors],
};

export default ColorPickerButton;
