import { ReactEditor } from "slate-react";
import { fontFamilyMap, sizeMap } from "../utils/font";
import { Editor, Node } from "slate";

export const breakpoints = {
  small: 0,
  mobile: 600,
  tablet: 900,
};

export const BREAKPOINTS_DEVICES = ["xs", "sm", "md", "lg"];

export const getDevice = (width) => {
  if (width > 0 && width < breakpoints.mobile) {
    return "xs";
  } else {
    return "lg";
  }
};

const copyAllLg = (value, ot) => {
  if (value && value["lg"] !== undefined) {
    return value;
  }

  return BREAKPOINTS_DEVICES.reduce((a, b) => {
    return {
      ...a,
      [b]: overrides[ot] ? overrides[ot](value) : value,
    };
  }, {});
};

const overrideValues = (value, ot) => {
  return Object.keys(value).reduce((a, b) => {
    return {
      ...a,
      [b]: overrides[ot] ? overrides[ot](value[b], value) : value,
    };
  }, {});
};

const overrides = {
  overrideText: (val) => {
    return sizeMap[val] || val;
  },
  overrideGridSize: (val) => {
    return `${((val || 8) / 12) * 100}%`;
  },
  overrideReSize: (val) => {
    return `${val?.widthInPercent || 100}%`;
  },
  overrideReSizeH: (val) => {
    return `${val?.height}px`;
  },
  overrideBorderRadius: (val, breakpointValues) => {
    if (!val?.topLeft) {
      Object.values(breakpointValues || {}).forEach((v) => {
        if (v) {
          // Applying the values from breakpoints that exist to those that do not have
          val = v;
        }
      });
    }

    return `${val?.topLeft}px ${val?.topRight}px ${val?.bottomLeft}px ${val?.bottomRight}px`;
  },
};

export const getBreakPointsValue = (
  value,
  breakpoint,
  ot = null,
  ov = false
) => {
  try {
    if (breakpoint) {
      if (typeof value !== "object") {
        return value;
      }

      if (value && value["lg"] === undefined) {
        let val = copyAllLg(value, ot);
        return val ? val[breakpoint] || val["lg"] : val;
      } else {
        return value ? value[breakpoint] || value["lg"] : value;
      }
    } else if (typeof value === "object") {
      return !breakpoint && value["lg"]
        ? !ov
          ? value
          : overrideValues(value, ot)
        : value[breakpoint] || copyAllLg(value, ot);
    } else {
      // consider without breakpoints
      return copyAllLg(value, ot);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getTRBLBreakPoints = (value, breakpoint) => {
  try {
    const values = getBreakPointsValue(value, breakpoint);
    const cssVal = BREAKPOINTS_DEVICES.reduce((a, b) => {
      if (values[b] || values["lg"]) {
        const { top, right, bottom, left } = values[b] || values["lg"];
        return {
          ...a,
          [b]: `${top || 0}px ${right || 0}px ${bottom || 0}px ${left || 0}px`,
        };
      } else {
        return a;
      }
    }, {});
    if (breakpoint) {
      return value[breakpoint] || value["lg"] || value;
    } else {
      return cssVal;
    }
  } catch (err) {
    console.log(err);
  }
};

export function getElementStyle(editor, element, stylePropertyName) {
  try {
    const path = ReactEditor.findPath(editor, element);

    if (path?.length) {
      const currentEle = Node.get(editor, path);
      const dom = ReactEditor.toDOMNode(editor, currentEle);
      const editorBtn = dom?.querySelector("button.theme-element");

      const elementStyle = window.getComputedStyle(editorBtn);

      const style = elementStyle[stylePropertyName];

      if (stylePropertyName === "fontFamily") {
        const val = Object.entries(fontFamilyMap).find(
          ([key, value]) => value === style
        )?.[0];

        return val;
      }

      return style;
    }
  } catch (err) {
    // console.log(err);
  }
}

export function getTextSizeVal(editor) {
  try {
    const currentNode = Node.get(editor, editor.selection.anchor.path);
    const currentElement = ReactEditor.toDOMNode(editor, currentNode);

    if (currentElement) {
      const element = document.querySelector('span[data-slate-string="true"]');

      const computedStyle = window.getComputedStyle(element);
      return computedStyle.getPropertyValue("font-size") || "";
    }
  } catch (err) {
    // console.log(err);
  }
}

export function getVariableValue(val) {
  const bodyElement = document.body;
  const computedStyle = getComputedStyle(bodyElement);
  const [, variableName] = val?.match(/var\((--[^)]+)\)/) || [];
  const varValue = computedStyle.getPropertyValue(variableName).trim();

  return varValue;
}

export const textThemeFields = [
  "fontFamily",
  "fontSize",
  "color",
  "bold",
  "italic",
  // "underline",
  // "strikethrough",
];

const themeElements = [
  "headingOne",
  "headingTwo",
  "headingThree",
  "headingFour",
  "headingFive",
  "headingSix",
  "paragraphOne",
  "paragraphTwo",
  "paragraphThree",
];

export const isTextCustomized = (editor) => {
  try {
    if (editor.selection) {
      const currentElement = Node.parent(
        editor,
        editor?.selection?.anchor?.path
      );

      if (!currentElement) {
        return false;
      }

      const { children, type } = currentElement;

      if (!themeElements.includes(type)) {
        return false;
      }

      if (children?.length > 1) {
        return true;
      }

      const customized = textThemeFields.some((field) => {
        const element = children[0] || {};
        const value = element[field];

        if (field === "fontSize") {
          return Object.keys(value || {}).length;
        } else {
          return value;
        }
      });

      return customized;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const addToTheme = (field, value, element) => {
  switch (field) {
    case "fontFamily":
      return { fontFamily: fontFamilyMap[value] };
    case "fontSize":
      return { fontSize: value?.lg };
    case "color":
      return { color: value };
    case "bold":
      return { fontWeight: "bold" };
    case "italic":
      return { fontStyle: "italic" };
    // case "underline":
    // case "strikethrough":
    //   let textDecoration = "";

    //   if (element.underline) {
    //     textDecoration = "underline";
    //   }

    //   if (element.strikethrough) {
    //     textDecoration += " strikethrough";
    //   }

    //   return { textDecoration };
    default:
      return {};
  }
};

const MAP_TEXT_THEME_FIELD = {
  headingOne: "h1",
  headingTwo: "h2",
  headingThree: "h3",
  headingFour: "h4",
  headingFive: "h5",
  headingSix: "h6",
  paragraphOne: "para1",
  paragraphTwo: "para2",
  paragraphThree: "para3",
};

export const saveToTheme = (editor) => {
  try {
    if (editor.selection) {
      const currentElement = Node.parent(
        editor,
        editor?.selection?.anchor?.path
      );

      if (!currentElement) {
        return false;
      }

      const { children } = currentElement;

      if (children?.length > 1) {
        return true;
      }

      let theme = {};

      textThemeFields.forEach((field) => {
        const element = children[0] || {};
        const value = element[field];

        let style = {};

        let isValueExist = false;

        if (value) {
          isValueExist = true;

          if (field === "fontSize") {
            isValueExist = !!Object.keys(value).length;
          }
        }

        if (isValueExist) {
          style = addToTheme(field, value, element);
        }

        theme = {
          ...theme,
          ...style,
        };
      });

      textThemeFields.forEach((field) => {
        Editor.removeMark(editor, field);
      });

      return {
        field: MAP_TEXT_THEME_FIELD[currentElement?.type],
        theme,
      };
    }
  } catch (err) {
    return;
  }
};

export const HEADING_THEME_FIELDS = ["h1", "h2", "h3", "h4", "h5", "h6"];

export const PARAGRAPH_THEME_FIELDS = ["para1", "para2", "para3"];
export const getCustomizationValue = (value) =>
  isNaN(parseInt(value)) ? null : parseInt(value);
