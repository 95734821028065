import { useMemo } from "react";
import {
  activeMark,
  addMarkData,
  isBlockActive,
  toggleBlock,
} from "../../../utils/SlateUtilityFunctions";
import CustomSelectTool from "./CustomSelectTool";
import useWindowResize from "../../../hooks/useWindowResize";
import {
  BREAKPOINTS_DEVICES,
  getBreakPointsValue,
  isTextCustomized as isCustomized,
  textThemeFields as themeFields,
} from "../../../helper/theme";
import { sizeMap } from "../../../utils/font";
import { Editor } from "slate";
import { MenuItem, Select } from "@mui/material";
import { useEditorTheme } from "../../../hooks/useEditorTheme";

function Label({ label, type }) {
  return (
    <span>
      <strong>{label}</strong> {type}
    </span>
  );
}

function DisplayHeaderLabel({ type, isCustomized, isPara = false }) {
  return (
    <div>
      {isCustomized ? "*" : ""}
      {isPara ? "P" : "H"}
      <sub>{type}</sub>
    </div>
  );
}

function getTypographyOptions(isCustomized) {
  const typographyOptions = [
    {
      id: 11,
      format: "headingOne",
      type: "block",
      title: <Label label="H1" type="Heading" />,
      label: <DisplayHeaderLabel type={1} isCustomized={isCustomized} />,
      group: "typography",
      value: "headingOne",
    },
    {
      id: 12,
      format: "headingTwo",
      type: "block",
      title: <Label label="H2" type="Heading" />,
      label: <DisplayHeaderLabel type={2} isCustomized={isCustomized} />,
      group: "typography",
      value: "headingTwo",
    },
    {
      id: 13,
      format: "headingThree",
      type: "block",
      title: <Label label="H3" type="Heading" />,
      label: <DisplayHeaderLabel type={3} isCustomized={isCustomized} />,
      group: "typography",
      value: "headingThree",
    },
    {
      id: 17,
      format: "headingFour",
      type: "block",
      title: <Label label="H4" type="Heading" />,
      label: <DisplayHeaderLabel type={4} isCustomized={isCustomized} />,
      group: "typography",
      value: "headingFour",
    },
    {
      id: 18,
      format: "headingFive",
      type: "block",
      title: <Label label="H5" type="Heading" />,
      label: <DisplayHeaderLabel type={5} isCustomized={isCustomized} />,
      group: "typography",
      value: "headingFive",
    },
    {
      id: 19,
      format: "headingSix",
      type: "block",
      title: <Label label="H6" type="Heading" />,
      label: <DisplayHeaderLabel type={6} isCustomized={isCustomized} />,
      group: "typography",
      value: "headingSix",
    },
    {
      id: 20,
      format: "paragraphOne",
      type: "block",
      title: <Label label="P1" type="Paragraph" />,
      label: (
        <DisplayHeaderLabel
          type={1}
          isCustomized={isCustomized}
          isPara={true}
        />
      ),
      group: "typography",
      value: "paragraphOne",
    },
    {
      id: 21,
      format: "paragraphTwo",
      type: "block",
      title: <Label label="P2" type="Paragraph" />,
      label: (
        <DisplayHeaderLabel
          type={2}
          isCustomized={isCustomized}
          isPara={true}
        />
      ),
      group: "typography",
      value: "paragraphTwo",
    },
    {
      id: 22,
      format: "paragraphThree",
      type: "block",
      title: <Label label="P3" type="Paragraph" />,
      label: (
        <DisplayHeaderLabel
          type={3}
          isCustomized={isCustomized}
          isPara={true}
        />
      ),
      group: "typography",
      value: "paragraphThree",
    },
    {
      id: 14,
      format: "fontSize",
      type: "mark",
      title: <Label label="L" type="Large" />,
      label: "L",
      group: "typography",
      value: "huge",
    },
    {
      id: 15,
      format: "fontSize",
      type: "mark",
      title: <Label label="M" type="Medium" />,
      label: "M",
      group: "typography",
      value: "medium",
    },
    {
      id: 16,
      format: "fontSize",
      type: "mark",
      title: <Label label="S" type="Small" />,
      label: "S",
      group: "typography",
      value: "small",
    },
  ];

  return typographyOptions;
}

function SelectTypography({ editor, classes, closeMainPopup, type }) {
  const [size] = useWindowResize();
  const { theme } = useEditorTheme();

  const isTextCustomized = theme?.id ? isCustomized(editor) : false;
  const typographyOptions = getTypographyOptions(isTextCustomized);

  const updateMarkData = (newVal) => {
    const val = activeMark(editor, "fontSize");

    let upData = {
      ...getBreakPointsValue(val),
      [size?.device]: `${newVal}px`,
    };

    // if desktop update to all other devices
    // to avoid default normal size
    if (size?.device === "lg") {
      upData = BREAKPOINTS_DEVICES.reduce((a, b) => {
        a[b] = `${newVal}px`;
        return a;
      }, {});
    }

    addMarkData(editor, {
      format: "fontSize",
      value: {
        ...upData,
      },
    });

    closeMainPopup();
  };

  const selectedBlock = useMemo(() => {
    return typographyOptions.find((t) => {
      if (t.type === "block") {
        return isBlockActive(editor, t.format);
      } else if (t.type === "mark") {
        const val = activeMark(editor, t.format);
        if (val) {
          const value = getBreakPointsValue(val, size?.device);

          let selected;

          Object.entries(sizeMap).forEach(([key, v]) => {
            if (v === value) {
              selected = key;
            }
          });

          return selected === t.value;
        }
      }

      return false;
    });
  }, [typographyOptions, activeMark, isBlockActive, editor]);

  const onChange = (format, option) => {
    if (option.type === "block") {
      themeFields.forEach((field) => {
        Editor.removeMark(editor, field);
      });

      // add/reset block elements
      toggleBlock(editor, format);
    } else if (option.type === "mark") {
      const size = sizeMap[option.value] || "";
      const [sizeInNumber] = size.split("px");
      updateMarkData(Number(sizeInNumber));
    }
  };

  const typographyValue = selectedBlock?.value || "headingOne";

  if (type === "miniToolBar") {
    return (
      <CustomSelectTool
        options={typographyOptions}
        editor={editor}
        onChange={onChange}
        value={typographyValue}
        classes={classes}
      />
    );
  }

  return (
    <Select
      value={typographyValue}
      className="editor-dd"
      onChange={(e) => {
        const { value } = e.target;
        const option = typographyOptions?.find((o) => o.value === value);

        onChange(value, option);
      }}
      style={{
        width: "100%",
        height: "36px",
        borderRadius: "10px",
        fontSize: "14px",
      }}
    >
      {typographyOptions.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.title}{" "}
          {isTextCustomized && item.value === typographyValue ? " *" : ""}
        </MenuItem>
      ))}
    </Select>
  );
}

export default SelectTypography;
