const LinkPopupStyles = (themeType) => ({
  addLinkField: {
    "& .MuiOutlinedInput-input": {
      fontSize: "12px",
      fontWeight: 500,
      color: themeType === "dark" ? "#FFFFFF !important" : "#000000 !important",
    },
    "& .MuiFormHelperText-root": {
      color: themeType === "dark" ? "#FFFFFF !important" : "#000000 !important",
    },
    "& .MuiOutlinedInput-root": {
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.16)",
      color: themeType === "dark" ? "#FFFFFF !important" : "#000000 !important",
      borderRadius: "7px",
      "& fieldset": {
        borderColor: "#D8DDE1",
      },
      "&:hover fieldset": {
        borderColor: "#64748B",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2563EB",
      },
      "& .MuiFormLabel-root": {},
    },
  },
});

export default LinkPopupStyles;
