const usePopupStyle = (theme) => ({
  popupWrapper: {
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.16)",
    zIndex: 1300,
    marginBottom: "12px !important",
    borderRadius: "6px",
    maxWidth: "96%",
    "&.fullscreen": {
      marginBottom: "0px !important",
      "& .papper-wrpr": {
        boxShadow: "none",
      },
    },
    "&.xs": {
      transform: "none !important",
    },
    "& .MuiPaper-root": {
      backgroundColor: theme?.palette?.editor?.background,
    },

    "&.textSettings": {
      "@media only screen and (max-width: 600px)": {
        margin: "4px !important",
        marginBottom: "54px !important",
      },
    },

    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiInputBase-root": {
      color: theme?.palette?.editor?.textColor,
      "& svg": {
        color: theme?.palette?.editor?.textColor,
      },
      "&.MuiOutlinedInput-root:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme?.palette?.editor?.textColor}`,
        },
      },
    },
    "& .MuiCheckbox-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme?.palette?.editor?.borderColor}`,
    },
    "& button": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .buttonsWrpr": {
      "& button": {
        color: theme?.palette?.editor?.textColor,
      },
    },
    "@media only screen and (max-width: 599px)": {
      padding: "10px",
      background: "unset",
      boxShadow: "unset",
      border: "none",
    },
  },
  textFormatWrapper: {
    padding: "0px 16px 16px 16px",
    width: "370px",
    maxWidth: "100%",
    // 30% of window height
    maxHeight: `${window.innerHeight * 0.45}px`,
    overflow: "auto",
    "&.elements-wrpr-pp": {
      width: "500px",
      maxWidth: "100%",
      "&.fullscreen": {
        width: "100%",
        maxHeight: "fit-content",
      },
      "& .ele-item-single": {
        paddingLeft: "4px",
        paddingTop: "4px",
      },
      "@media only screen and (max-width: 599px)": {
        width: "330px",
      },
    },
    "&.templates": {
      width: "500px",
      maxWidth: "100%",
      "&.fullscreen": {
        width: "100%",
        maxHeight: "fit-content",
      },
      "@media only screen and (max-width: 599px)": {
        width: "330px",
      },
    },
    "& .headerContainer": {},
    "& .textSettingHeader": {
      borderBottom: "1px solid #DCE4EC",
      padding: "8px 0px 5px",
      marginBottom: "20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme?.palette?.editor?.borderColor}`,
    },
    "& .MuiOutlinedInput-root": {
      boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
    },
    "& .mini": {
      borderRadius: "0px",
      width: "100%",
      justifyContent: "start",
      "& svg": {
        width: "24px",
        height: "24px",
        marginRight: "8px",
      },
      "& span": {
        fontSize: "14px",
        color: "#64748B",
      },
    },
    // for command list style
    "& .cmd": {
      borderRadius: "0px",
      width: "100%",
      justifyContent: "start",
      "& svg": {
        width: "24px",
        height: "24px",
        marginRight: "8px",
      },
      "& span": {
        fontSize: "14px",
        color: "#64748B",
      },
    },
    "& .MuiTab-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& button": {
      "&:hover": {
        "& span": {
          color: theme?.palette?.editor?.activeColor,
        },
      },
    },
    "@media only screen and (max-width: 599px)": {
      width: "330px",
    },
  },
  textFormatLabel: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "25px",
    marginTop: "12px",
    marginBottom: "8px",
    "& .searchContainer": {
      position: "absolute",
      right: 0,
      zIndex: 3,
    },
    "& .MuiFormControl-root.MuiTextField-root input": {
      padding: "8px 35px 6px 12px",
    },
  },
  textFormatField: {
    marginBottom: "16px",
  },
  textFormatFieldBorder: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    border: "1px solid #ECECEC",
    borderRadius: "8px",
    boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
  },
  textFormatColorWrpr: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  textFormatCG: {},
  evenSpace: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& button": {
      marginRight: "8px",
      marginBottom: "8px",
    },
    "&.typo-icons": {
      "& button": {
        width: "36px",
        height: "36px",
      },
    },
    "&.text-decorations-wrpr": {
      "& button": {
        // width: "140px",
      },
    },
  },
  typoLabel: {
    fontSize: "14px",
    marginBottom: "5px",
    paddingLeft: "5px",
    fontWeight: 500,
    color: "#000000",
  },
  templateCard: {
    borderRadius: "10px",
    boxShadow: "none",
    cursor: "pointer",
  },
  templateCardMedia: {
    width: "100%",
    height: "210px",
    backgroundPosition: "left top",
    backgroundSize: "100% auto",
    borderRadius: "10px",
    boxShadow: "0px 1px 3px 3px rgba(0,0,0,0.12)",
    zIndex: 1,
    position: "relative",
    "&.fullscreen": {
      height: "280px",
    },
  },
  templateCardMediaWrpr: {
    position: "relative",
    padding: "4px",
    margin: "8px",
    marginBottom: "0px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    backgroundColor: "#FEFEFE",
    overflow: "hidden",
    "&:hover": {
      border: "1px solid #2563EB",
      "& .template-card-action": {
        display: "flex",
      },
    },
    "& .img-loader-wrapper": {
      position: "absolute",
      width: "12px",
      height: "12px",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: "auto",
      zIndex: 0,
    },
  },
  templateCardsWrpr: {
    height: "100%",
    overflow: "auto",
    margin: "12px 0px",
    paddingRight: "8px",
    "&.fullscreen": {
      height: `${window.innerHeight - 197}px`,
    },
  },
  templateCardTitle: {
    fontSize: "12px",
    padding: "6px 8px",
  },
  pageWidthBtn: {
    color: "#64748B",
    marginTop: "8px",
    textTransform: "capitalize",
    "&.active": {
      backgroundColor: theme?.palette?.editor?.background,
      border: `1px solid transparent`,
      color: theme?.palette?.editor?.activeColor,
    },
  },
  btnGroup: {
    backgroundColor: theme?.palette?.editor?.background,
    "& button": {
      backgroundColor: theme?.palette?.editor?.background,
      marginRight: "0px",
      color: theme?.palette?.editor?.textColor,
      borderColor: theme?.palette?.editor?.borderColor,
      textTransform: "capitalize",
      marginBottom: "0px",
      "&:hover": {
        color: theme?.palette?.editor?.borderColor,
        background: theme?.palette?.editor?.background,
      },
      "&.active": {
        background: theme?.palette?.editor?.background,
        color: theme?.palette?.editor?.activeColor,
      },
      "&.no-hover": {
        border: `1px solid ${theme?.palette?.editor?.borderColor}`,
      },
    },
  },
  allColor: {
    "& .buttonsWrpr": {
      "& button": {
        color: theme?.palette?.editor?.textColor,
      },
    },
  },
  allColorInner: {
    "& .buttonsWrpr": {
      "& button": {
        border: "1px solid #ccc",
        margin: "4px",
        width: "24px",
        height: "24px",
        "&.active": {
          "&:before": {
            content: '" "',
            position: "absolute",
            top: "-2px",
            left: "-2px",
            width: "calc(100% + 4px)",
            height: "calc(100% + 4px)",
            border: "3px solid blue",
            borderRadius: "50%",
          },
        },
      },
    },
  },
  defaultBtn: {
    color: "#2563EB !important",
    textTransform: "none",
    textDecoration: "underline",

    "&.Mui-disabled": {
      color: "#A0AEC0 !important",
      textDecoration: "none",
    },
  },
  templateCardBtnGrp: {
    display: "none",
    position: "absolute",
    flexDirection: "column",
    left: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    width: "100%",
    background: "linear-gradient(180deg, #0F172A00, #0f172ae0 96%)",
    padding: "10px",
    boxSizing: "border-box",
    "& .blueBtn": {
      background: "#2563EB !important",
      borderRadius: "7px",
      padding: "7px 15px",
      color: "#FFF",
      width: "100px",
      marginBottom: "10px",
    },
    "& .outlineBtn": {
      border: "1px solid #D7DBEC",
      borderRadius: "7px",
      padding: "7px 15px",
      color: "#FFF",
      width: "100px",
    },
  },
  searchBox: {
    "& input": {
      padding: "6px 12px",
    },
    "& svg": {
      color: "#CCC",
    },
  },
  textOptions: {
    backgroundColor: theme?.palette?.editor?.background,
    color: theme?.palette?.editor?.textColor,
  },
  textSize: {
    "& .textFontArrows": {
      "& svg": {
        stroke: theme?.palette?.editor?.textColor,
      },
    },
  },
  closeBtn: {
    backgroundColor: theme?.palette?.editor?.background,
    color: theme?.palette?.editor?.textColor,
  },
  colorPickerPopup: {
    "& .MuiPaper-root": {
      overflow: "auto",
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .popup_tabs": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .popup_tabs-header": {
      backgroundColor: theme?.palette?.editor?.background,
      "& .popup_tabs-header-label-active": {
        color: theme?.palette?.editor?.activeColor,
        backgroundColor: theme?.palette?.editor?.background,
      },
    },
    "& .colorpicker": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .color-picker-panel": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .input_rgba": {
      "& input": {
        backgroundColor: theme?.palette?.editor?.background,
        color: theme?.palette?.editor?.textColor,
      },
    },
  },
  colorPopper: {
    "& .MuiPaper-root": {
      backgroundColor: theme?.palette?.editor?.background,
      padding: "4px 14px",

      "@media only screen and (max-width: 600px)": {
        marginTop: "-40px",
      },
    },
  },
  fullViewCardMedia: {
    margin: "5px 0px",
    width: "100%",
    backgroundPosition: "left top",
    backgroundSize: "100%, auto",
    zIndex: 1,
    position: "relative",
    "&.fullscreen": {
      height: "130px",
    },
    "&.smallCard": {
      height: "130px",
    },
    "&.mediumCard": {
      height: "250px",
      boxShadow: "0px 1px 3px 3px rgba(0,0,0,0.12)",
    },
  },
  buttonCardMedia: {
    margin: "5px 0px",
    width: "100%",
    height: "40px",
    backgroundPosition: "center",
    backgroundSize: "contain",
    zIndex: 1,
    position: "relative",
    "&.fullscreen": {
      height: "48px",
    },
  },
  buttonCardMediaWrpr: {
    padding:'5px',
    position: "relative",
    margin: "8px",
    marginBottom: "0px",
    '& .img-wrapper':{
      "&:hover": {
        padding:'0px 2px 0px 2px',
        backgroundColor : '#E9F3FE',
        border: '1px solid #2563EB40',
        borderRadius:'5px',
        height: "100%",
        margin: "0px",
        // "& .template-card-action": {
        //   display: "flex",
        // },
      },
    }
  },
  paperOverrides: {
    "&.MuiPaper-root": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  miniTextFormatWrapper: {
    maxWidth: "100%",
    padding: "4px 8px",
    overflowX: "auto",

    "& .customSelectTool": {
      padding: "0px 8px",
      color: theme?.palette?.secondary?.main,
      gap: "4px",
    },

    "& .verticalLine": {
      borderLeft: `1px solid ${theme?.palette?.primary?.border8 || "black"}`,
      minHeight: "20px",
      height: "100%",
    },

    "& .toolWrapper": {
      display: "flex",
      alignItems: "center",

      "& .mr-1": {
        marginRight: "8px",
      },

      "& .ml-1": {
        marginLeft: "8px",
      },
    },

    "& button": {
      minWidth: "0px !important",
      textTransform: "none",
    },

    "& .fontColorBtn": {
      color: theme?.palette?.secondary?.main,
      borderRadius: "unset",
      fontSize: "17px",
      padding: "0px",
      margin: "0px 8px",
      paddingBottom: "5px",
      lineHeight: "16px",
      position: "relative",

      "& .selectedColor": {
        height: "3px",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
      },
    },

    "& .textSettingsIcon": {
      minWidth: "auto !important",
    },
  },
  customSelectPopoverWrapper: {
    "& .MuiPopover-paper": {
      maxHeight: "140px",
      background: theme?.palette?.editor?.background,

      "@media only screen and (max-width: 600px)": {
        marginTop: "-40px",
      },
    },

    "& .customSelectOptionLabel": {
      color: theme?.palette?.editor?.textColor || "black",
      margin: "0px",
      width: "100%",
      justifyContent: "start",
      paddingRight: "20px",
      fontSize: "14px",
      lineHeight: "1.75 !important",
      fontWeight: 400,
      background: theme?.palette?.editor?.background || "#fff",

      "&:hover": {
        background: `${theme?.palette?.action?.selected} !important`,
      },

      "&.selected": {
        color: `${theme?.palette?.primary?.main} !important`,
        background: `${theme?.palette?.action?.selected} !important`,
      },
    },

    "& .menuOptions": {
      display: "flex",
      alignItems: "center",
      gap: "6px",
    },

    "& button": {
      minWidth: "0px !important",
      textTransform: "none",
    },
  },
  // mobileMiniTextWrapper: {
  //   boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.16)",
  //   position: "fixed",
  //   bottom: "env(safe-area-inset-bottom)",
  //   left: 0,
  //   width: "100%",
  // },
  miniFontSizeInput: {
    "& input": {
      fontSize: "14px",
      width: "16px",
      padding: "8px 4px",
    },

    "& fieldset": {
      border: "none !important",
    },
  },
});

export default usePopupStyle;
