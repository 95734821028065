import {
  Button,
  Grid,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEditorContext } from "../../hooks/useMouseMove";
import { fontFamilyMap, fontOptions } from "../../utils/font";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import usePopupStyles from "../PopupTool/PopupToolStyle";
import { TextMinusIcon, TextPlusIcon } from "../../common/iconslist";
import {
  BREAKPOINTS_DEVICES,
  getBreakPointsValue,
  getVariableValue,
} from "../../helper/theme";
import useWindowResize from "../../hooks/useWindowResize";
import { toolbarGroups } from "../toolbarGroups";
import Icon from "../../common/Icon";
import { useMemo, useState } from "react";
import CommonButton from "../../common/Button";
import CustomSelect from "../../common/CustomSelect";
import ColorButtons from "../../Elements/Color Picker/ColorButtons";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";
import ColorPickerStyles from "../../Elements/Color Picker/Styles";
import colorWheel from "../../Elements/Color Picker/colorWheel.png";
import CustomColorPicker from "../../common/CustomColorPicker";

function getFontStyleProps(settings = {}, format) {
  const { fontWeight, fontStyle } = settings;

  let isActive;
  let onClick;

  switch (format) {
    case "bold":
      isActive = fontWeight === "bold" || fontWeight === "700";
      onClick = () => {
        return { fontWeight: isActive ? "normal" : "bold" };
      };
      break;

    case "italic":
      isActive = fontStyle === "italic";
      onClick = () => {
        return { fontStyle: isActive ? "unset" : "italic" };
      };
      break;

    // case "underline":
    //   isActive = textDecoration.includes("underline");
    //   onClick = () => {
    //     let otherTextDecoration = textDecoration?.includes("line-through")
    //       ? "line-through"
    //       : "";

    //     otherTextDecoration += isActive ? "" : " underline";

    //     return { textDecoration: otherTextDecoration };
    //   };
    //   break;
    // case "strikethrough":
    //   isActive = textDecoration.includes("line-through");
    //   onClick = () => {
    //     let otherTextDecoration = textDecoration?.includes("underline")
    //       ? "underline"
    //       : "";

    //     otherTextDecoration += isActive ? "" : " line-through";

    //     return { textDecoration: otherTextDecoration };
    //   };
    //   break;

    default:
      isActive = false;
      onClick = () => {};
      break;
  }

  return {
    isActive,
    onClick,
  };
}

const allTools = toolbarGroups.flat();

function ThemeTextFormat(props) {
  const { settings, updateSettings, onSaveTextSettings, onClose } = props;

  const { theme } = useEditorContext();
  const classes = usePopupStyles(theme);
  const pickerStyles = ColorPickerStyles(theme);

  const fontStyle = allTools.filter((f) => f.type === "mark" && f.themeEnabled);
  const fontWeight = allTools.find((f) => f.format === "fontWeight");

  const fontFamilyVal = useMemo(() => {
    const { fontFamily = "" } = settings;

    let font = fontFamily.startsWith("var")
      ? getVariableValue(fontFamily)
      : fontFamily;

    return fontOptions?.find((f) => f.text === font)?.value;
  }, [settings, fontOptions]);

  const fontWeightVal = useMemo(() => {
    const { options } = fontWeight || {};

    const selected = options?.find(
      (o) =>
        o.value === settings?.fontWeight || o.numVal === settings?.fontWeight
    );

    return selected?.value;
  }, [settings?.fontWeight]);

  return (
    <Grid container sx={classes.textFormatWrapper}>
      <Grid item xs={12}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography
              variant="body1"
              color={"primary"}
              sx={classes.typoLabel}
            >
              Font Family
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={classes.defaultBtn}
              startIcon={<FormatClearIcon />}
              onClick={() =>
                updateSettings({ fontFamily: Object.values(fontFamilyMap)[0] })
              }
            >
              Default
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={classes.textFormatField}>
          <CustomSelect
            options={fontOptions}
            classes={classes}
            onChange={(e) => {
              const fontFamily = fontOptions.find(
                (f) => f.value === e.target.value
              )?.text;

              updateSettings({ fontFamily });
            }}
            value={fontFamilyVal || fontOptions[0].value}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
            Font Weight
          </Typography>
          <Grid item xs={12} sx={classes.textFormatField}>
            <CustomSelect
              options={fontWeight?.options || []}
              classes={classes}
              onChange={(e) => {
                updateSettings({ fontWeight: e.target.value });
              }}
              value={fontWeightVal}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
            Font Size
          </Typography>
          <Grid item xs={12} sx={classes.textFormatCG}>
            <TextSize
              classes={classes}
              value={settings?.fontSize || 16}
              onChange={(fontSize) => updateSettings({ fontSize })}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={classes.textFormatField}
        style={{ marginTop: "14px" }}
      >
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography
              variant="body1"
              color={"primary"}
              sx={classes.typoLabel}
            >
              Text Color
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={classes.defaultBtn}
              startIcon={<FormatColorResetIcon />}
              onClick={() => updateSettings({ color: "#ddd" })}
            >
              Default
            </Button>
          </Grid>
        </Grid>
        <Grid sx={classes.textFormatColorWrpr}>
          <ColorPicker
            classes={classes}
            pickerStyles={pickerStyles}
            onChange={(color) => updateSettings({ color })}
            color={settings?.color}
            disableEditTheme={true}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
          Typography
        </Typography>
        <Grid item xs={12} className="typo-icons" sx={classes.evenSpace}>
          {fontStyle?.map((m, i) => {
            const { isActive, onClick } =
              getFontStyleProps(settings, m.format) || {};

            return (
              <CommonButton
                active={isActive}
                key={i}
                onClick={() => {
                  const style = onClick();
                  updateSettings(style);
                }}
              >
                <Icon icon={m.format} />
              </CommonButton>
            );
          })}
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            margin: "8px",
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        >
          <Button onClick={onClose} className="secondaryBtn">
            Cancel
          </Button>
          <Button onClick={onSaveTextSettings} className="primaryBtn">
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default ThemeTextFormat;

function TextSize(props) {
  const { classes, value: val, onChange, fullWidth } = props;

  const [size] = useWindowResize();
  const value = getBreakPointsValue(val, size?.device);

  const updateMarkData = (newVal) => {
    let upData = {
      ...getBreakPointsValue(val),
      [size?.device]: `${newVal}px`,
    };

    // if desktop update to all other devices
    // to avoid default normal size
    if (size?.device === "lg") {
      upData = BREAKPOINTS_DEVICES.reduce((a, b) => {
        a[b] = `${newVal}px`;
        return a;
      }, {});
    }

    onChange(upData);
  };

  const onChangeSize = (e) => {
    let inc = parseInt(e.target.value) || 8;
    inc = inc > 200 ? 200 : inc;
    updateMarkData(inc || 8);
  };

  const getSizeVal = () => {
    try {
      return parseInt(value);
    } catch (err) {
      return "";
    }
  };

  const combinedOldVal = getSizeVal();

  const onIncreaseSize = () => {
    let inc = (combinedOldVal || 0) + 1;
    inc = inc > 200 ? 200 : inc;
    updateMarkData(inc);
  };

  const onDecreaseSize = () => {
    const newVal = combinedOldVal - 1 < 0 ? 0 : combinedOldVal - 1;
    updateMarkData(newVal);
  };

  return (
    <TextField
      sx={classes?.textSize}
      value={combinedOldVal}
      onChange={onChangeSize}
      size="small"
      inputProps={{
        style: {
          width: fullWidth ? "100%" : "30px",
          textAlign: "center",
          height: "19px",
          fontSize: "14px",
        },
      }}
      fullWidth={fullWidth}
      InputProps={{
        style: { borderRadius: "8px" },
        endAdornment: (
          <div className="textFontArrows">
            <IconButton onClick={onIncreaseSize} size="small">
              <TextPlusIcon />
            </IconButton>
            <IconButton onClick={onDecreaseSize} size="small">
              <TextMinusIcon />
            </IconButton>
          </div>
        ),
      }}
    />
  );
}

function ColorPicker(props) {
  const { classes, pickerStyles, onChange, color, disableEditTheme } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={""} arrow>
        <IconButton
          sx={pickerStyles.colorPickerIcon}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <img src={colorWheel} alt="color wheel" />
        </IconButton>
      </Tooltip>

      <ColorButtons
        classes={pickerStyles}
        onSelect={onChange}
        activeColor={color}
        disableEditTheme={disableEditTheme}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "top",
        }}
        sx={classes.colorPickerPopup}
      >
        <CustomColorPicker
          gradient
          onChange={onChange}
          color={color}
          disableEditTheme={disableEditTheme}
        />
      </Popover>
    </>
  );
}
